.permission__box {
  display: flex;
  max-width: fit-content;
  flex-direction: column;
  background-color: #fff;
  padding: 30px;
  color: #000000;
  * {
    font-family: "Poppins";
  }
  .heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
  }
  .subHeading {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    mix-blend-mode: normal;
    opacity: 0.4;
    margin-bottom: 20px;
  }
}

.swiper {
  display: flex;
  max-width: 55rem;
  &::-webkit-scrollbar {
    display: none;
  }
  overflow: auto;
  flex: none;
  flex-flow: row nowrap;
  width: 100%;
  scroll-snap-type: x mandatory;
  // border: 1px solid red;
  .screen {
    width: 100%;
    flex-shrink: 0;
    scroll-snap-align: center;
    flex: none;
  }
}

.swiper-cta {
  display: flex;
  grid-gap: 25px;
  margin-top: 50px;
}

.screen {
  display: flex;
  .left {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    padding: 2rem 0rem;
  }
  .right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .cross {
      display: none;
    }
    img {
      object-fit: contain;
      max-height: 20rem;
    }
  }

  .expanded {
    position: fixed;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.183);
    & > img {
      width: 55rem;
      max-height: none;
    }
    .cross {
      display: block;
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
  .box {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    max-width: 400px;
    ul {
      font-weight: 400;
    }
    .blue {
      color: #407bff;
    }
  }
  .box-1 {
    ul {
      margin-top: 25px;
      li {
        max-width: 600px;
      }
    }
  }
  .heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 144.2%;
  }
  .json-box {
    background: #000000;
    position: relative;
    padding: 10px;
    max-height: 12rem;
    overflow-y: auto;
    border-radius: 8px;
    .click-copy {
      position: sticky;
      width: fit-content;
      height: 0;
      top: 10px;
      left: 72%;
      cursor: pointer;
      img {
        height: 1rem;
        object-fit: contain;
      }
    }
  }
}

.step-counter {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.dashboard-settings {
  display: flex;
  margin-top: auto;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
}
