.waitlist-form {
  width: 40%;
  margin: 1.5em auto;
  border-radius: 1em;
}

@include media("<=xl") {
  .waitlist-form {
    width: 75%;
  }
}

@include media("<=medium") {
  .waitlist-form {
    width: 100%;
  }
}
