.flatbread-product {
  &-container {
    padding: 40px;
    display: flex;
    flex-direction: column;
    * {
      font-family: Poppins;
    }
    align-items: center;
    #olake-product-form{
      min-height: 600px;
    }
  }

  &-heading {
    text-align: center;
    margin-top: 38px;
    font-size: 48px;
    font-weight: 500;

    &-type1 {
      background: linear-gradient(135deg, #fff 0%, #71717a 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &-type2 {
      color: #fff;
      font-weight: 700;
    }

    &-type3 {
      color: #fff;
      font-weight: 400;
      line-height: normal;
    }
  }

  &-architecture {
    margin-top: 76px;
  }

  &-waitlist-button {
    all: unset;
    margin-top: 92px;

    cursor: pointer;
    display: flex;
    padding: 8px 48px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #fff;
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    /* 160% */
  }

  &-subheading {
    margin-top: 80px;
    max-width: 80%;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
    line-height: 48px;
    /* 200% */
    background: linear-gradient(93deg, #fff 0.36%, #bdbdbd 119.34%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-feature {
    border-radius: 8px;
    background: #202020;
    display: flex;
    // flex-direction: column;
    align-items: center;
    grid-gap: 40px;
    padding: 40px;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(6),
    &:nth-child(7) {
      grid-column: span 3;
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      grid-column: span 2;
    }

    &-box {
      display: flex;
      width: 100%;
      max-width: 90%;
      //   align-self: stretch;
      flex-direction: column;
      align-items: center;
      margin-top: 80px;
    }

    &-heading {
      color: #fff;
      text-align: center;
      font-size: 42px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      &-type1 {
        color: #8c66ff;
        font-family: Poppins;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      margin-top: 80px;
      grid-gap: 33px;
    }

    &-heading-box {
      display: flex;
      flex-direction: column;
    }

    &-inner-heading {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
    }

    &-subheading {
      margin-top: 15px;
      color: #8d8d8d;
      font-size: 16px;
      font-weight: 400;
    }
  }

  &-function {
    &-box {
      display: flex;
      width: 100%;
      max-width: 90%;
      flex-direction: column;
      align-items: center;
      margin-top: 80px;
    }

    &-heading {
      color: #fff;
      text-align: center;
      font-size: 42px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &-main {
      border-radius: 8px;
      align-self: stretch;
      margin-top: 40px;
      padding: 80px;
      background: #8c66ff;
      background: url("../../../../assets/images/flatbread-function-vector.svg"),
        #8c66ff;
      background-blend-mode: normal, hard-light, normal;
      background-repeat: no-repeat;
      background-position: bottom right;
      &-heading {
        color: #fff;

        font-size: 20px;
        font-weight: 500;
      }
      &-subheading {
        color: #111;
        font-size: 24px;

        font-weight: 500;
        max-width: 75%;
        display: flex;
        flex-direction: column;
        grid-gap: 40px;
        .box-1 {
          margin-top: 12px;
        }
        .box-2 {
        }
        .type-1 {
          color: #fff;
          font-weight: 500;
        }
      }
    }
  }

  &-form {
    &-container {
      display: flex;
      width: 100%;
      max-width: 90%;
      margin-top: 100px;
      background: url("../../../../assets/images/flatbread-banner-vector.svg"),
        linear-gradient(0deg, #202020 0%, #202020 100%),
        radial-gradient(
          169.05% 116.62% at 20.83% -12.91%,
          rgba(255, 255, 255, 0.3) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      background-blend-mode: normal, hard-light, normal;
      background-repeat: no-repeat;
      background-position: bottom left;

      &-left {
        flex: 1;
        flex-shrink: 0;
        padding-left: 50px !important;
        padding-top: 50px;
      }

      &-right {
        flex: 1;
        flex-shrink: 0;
        min-height: 300px;
        margin: 30px !important;
        padding: 40px !important;
        border-radius: 8px;
        border: 1px solid #f5f5f5;
        background: #fff;
      }
    }

    &-brand {
      display: flex;
      grid-gap: 10px;
      align-items: center;
      color: #fff;
      font-size: 24px;
      font-weight: 600;
    }

    &-heading {
      color: #fff;
      margin-top: 32px;
      font-size: 42px;
      font-style: normal;
      font-weight: 600;
      line-height: 56px;
      /* 133.333% */
    }
  }

  &-blog {
    padding: 32px;
    border-radius: 8px;
    background: #202020;
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 0 0;

    &-container {
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 90%;
    }

    &-main-heading {
      color: #fff;
      text-align: center;
      font-size: 42px;
      font-weight: 600;
      line-height: 52px;
      /* 125% */
      letter-spacing: -2.88px;
    }

    &-list {
      display: flex;
      align-items: center;
      grid-gap: 24px;
      margin-top: 60px;
    }

    &-tag {
      border-radius: 24px;
      align-self: flex-start;
      background: #333;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 12px;
      font-weight: 500;
      padding: 0px 12px;
    }

    &-heading {
      color: #fff;
      margin-top: 8px;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      /* 133.333% */
      letter-spacing: -0.72px;
    }

    &-subheading {
      margin-top: 8px;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      /* 137.5% */
    }

    &-link {
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      /* 100% */
    }
  }

  &-faq {
    margin-top: 80px;
    max-width: 90%;
  }
}

@include media("<=medium") {
  .flatbread-product {
    &-container {
      padding: 20px;

      * {
        font-family: Poppins !important;
      }

      #olake-product-form{
        min-height: 0px;
      }
    }

    &-heading {
      margin-top: 16px;
      font-size: 24px;
      line-height: 40px;
    }

    &-architecture {
      margin-top: 30px;
    }

    &-waitlist-button {
      margin-top: 32px;
      padding: 4px 48px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      /* 160% */
    }

    &-subheading {
      margin-top: 50px;
      max-width: 100%;
      font-size: 16px;
      line-height: 32px;
      /* 200% */
    }

    &-feature {
      padding: 20px;
      flex-direction: column;
      grid-gap: 10px;

      &-image {
        max-width: 80px;
      }
      &-box {
        max-width: none;
        margin-top: 50px;
      }

      &-heading {
        font-size: 24px;
      }

      &-list {
        margin-top: 40px;
        /* 6 equal columns */
        grid-gap: 30px;
        //   padding: 0px 40px;
      }

      &-inner-heading {
        font-size: 16px;
        text-align: center;
        margin-top: 12px;
      }

      &-subheading {
        margin-top: 12px;
        text-align: center;
        font-size: 14px;
      }
    }

    &-function {
      &-box {
        max-width: none;
        margin-top: 50px;
      }

      &-heading {
        font-size: 20px;
      }

      &-main {
        padding: 40px;
        background: #8c66ff;
        &-heading {
          font-size: 14px;
        }
        &-subheading {
          font-size: 16px;
          max-width: none;
          grid-gap: 20px;
          .box-1 {
            margin-top: 0px;
          }
          .box-2 {
          }
          .type-1 {
            color: #fff;
            font-weight: 500;
          }
        }
      }
    }

    &-form {
      &-container {
        max-width: none;
        margin-top: 50px;
        flex-direction: column;
        background: none, linear-gradient(0deg, #202020 0%, #202020 100%),
          radial-gradient(
            169.05% 116.62% at 20.83% -12.91%,
            rgba(255, 255, 255, 0.3) 0%,
            rgba(255, 255, 255, 0) 100%
          );

        &-left {
          padding: 20px !important;
        }

        &-right {
          margin: 20px !important;
          padding: 20px !important;
        }
      }

      &-brand {
        font-size: 20px;
        font-weight: 600;

        img {
          max-width: 80px;
        }
      }

      &-heading {
        margin-top: 12px;
        font-size: 24px;
        line-height: 32px;
        /* 133.333% */
      }
    }

    &-blog {
      padding: 20px;

      &-container {
        margin-top: 50px;
        max-width: none;
      }

      &-main-heading {
        font-size: 24px;
        line-height: 52px;
        /* 125% */
        letter-spacing: -1px;
      }

      &-list {
        grid-gap: 24px;
        margin-top: 24px;
        flex-direction: column;
      }

      &-heading {
        margin-top: 6px;
        font-size: 16px;
        line-height: 24px;
        /* 133.333% */
        letter-spacing: -0px;
      }

      &-subheading {
        margin-top: 4px;
        font-size: 12px;
        line-height: 22px;
        /* 137.5% */
      }

      &-link {
        font-size: 12px;
        line-height: 16px;
        /* 100% */
      }
    }

    &-faq {
      margin-top: 50px;
      max-width: none;
    }
  }
}
