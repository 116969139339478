.comparison {
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    grid-gap: 55px;
    font-family: "Poppins";
  }
  &-grid {
    display: grid;
    align-self: stretch;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    grid-gap: 45px;
  }
  &-box {
    display: flex;
    flex-direction: column;
    background: #0e1330;
    border: 1px solid rgba(40, 45, 69, 1);
    padding: 30px;
    grid-gap: 22px;
    .images {
      display: flex;
      grid-gap: 10px;
    }
    &-heading {
      font-size: 20px;
      color: #f6f6f7;
      line-height: 32px;
      font-weight: 700;
    }
    .read-more {
      display: flex;
      position: relative;
      width: fit-content;
      cursor: pointer;
      grid-gap: 12px;
      font-size: 14px;
      color: #599bf9;
      img {
        max-width: 20px;
        position: absolute;
        right: -40px;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: 0.3s all;
      }
      &:hover {
        img {
          right: -50px;
        }
      }
    }
  }
}

@include media("<=medium") {
  .comparison {
    &-wrapper {
      grid-gap: 25px;
      font-family: "Poppins";
    }
    &-grid {
      grid-gap: 25px;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }
    &-box {
      padding: 18px;
      .images {
        display: flex;
        grid-gap: 10px;
        img {
          max-width: 32px;
        }
      }
      &-heading {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
