.ingestion {
  &__landing {
    * {
      font-family: "Poppins";
    }
    .landing {
      &__box {
        background: transparent;
        position: relative;
        color: #fff;
        height: auto;
        padding-bottom: 55px;
        .landing-ellipse {
          position: absolute;
          width: 274px;
          height: 226.03px;
          background: #7214ff;
          left: -20%;
          top: 50%;
          filter: blur(250px);
        }
        .landing-ellipse-2 {
          position: absolute;
          width: 190px;
          height: 156.74px;
          background: #32cafd;
          right: -25%;
          bottom: 20%;
          filter: blur(195px);
        }
      }
      &__heading {
        color: #fff;
      }
      &__sub {
        color: #fff;
      }
      &__hosted {
        color: #fff;
      }
    }
    .button__secondary {
      border: 1px solid #fff;
      color: #fff;
    }
    .faq-box {
      display: flex;
      flex-direction: column;
      grid-gap: 40px;
      padding: 55px 0px;
    }
  }
  &__motto {
    display: flex;
    padding: 55px 0px;
    align-items: center;
    width: 100%;
    .motto {
      &-text {
        flex: 3;
        text-align: start;
      }
      &-sub {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        flex: 2;
      }
    }
  }

  &-connector {
    padding: 55px 0px;
    margin: 55px 0px;
    background: #0e1330;

    border: 1px solid #282d45;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-gap: 60px;
    .heading {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
    }
    .connectors {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      .item {
        display: flex;
        flex-direction: column;
        grid-gap: 12px;
        align-items: center;
        span {
          font-weight: 700;
          font-size: 16px;
          text-align: center;
          line-height: 140%;
          color: #fff;
        }
      }
    }
  }
  &-benifits {
    padding: 55px 0px;
    display: flex;
    flex-direction: column;
    .home-connector {
      width: 80%;
      align-self: center;
    }
  }
}

.motto-text {
  font-weight: 700;
  font-size: 56px;
  line-height: 64px;
  letter-spacing: -0.03em;
  text-align: center;
  background: linear-gradient(180deg, #f6f6f7 0%, #7e808f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.benifit-grid {
  // display: grid;
  // grid-template-columns: repeat(3, minmax(0, 1fr));
  // grid-template-rows: repeat(2, minmax(0, 1fr));
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  margin: 55px 0px;
  .grid-item {
    flex: 0 0 calc(33.33% - 20px);
    background: #0e1330;
    padding: 30px;
    border: 1px solid #282d45;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 12px;
    .heading {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.03em;
      color: #f6f6f7;
    }
    .sub {
      font-size: 16px;
      line-height: 24px;
      color: #8f9bb7;
    }
  }
}

.client-testimonial {
  display: flex;
  flex-direction: column;
  padding: 55px 0px;
  align-items: center;
  grid-gap: 35px;
  .subheading {
    font-size: 16px;
    max-width: 580px;
    text-align: center;
    line-height: 24px;
    text-align: center;
    color: #8f9bb7;
  }
}

.benifit-contact {
  width: 100%;
  margin: 55px 0px;
  background: #0e1330;
  height: fit-content;
  overflow: hidden;
  .use__freeTrial {
    margin: 100px auto;
    padding: 0px 40px;
    max-width: 1280px;
  }
}

@include media("<=medium") {
  .ingestion {
    &__landing {
      .landing {
        &__box {
          padding-top: 4rem;
          padding-bottom: 30px;
        }
      }
      .faq-box {
        grid-gap: 30px;
        padding: 30px 0px;
      }
    }
    &__motto {
      flex-direction: column;
      padding: 30px 0px;
      grid-gap: 20px;
      .motto {
        &-sub {
          font-size: 14px;
        }
      }
    }
    &-connector {
      padding: 30px 0px;
      margin: 30px 0px;
      grid-gap: 30px;
      .heading {
        font-size: 30px;
        line-height: 33px;
      }
      .connectors {
        justify-content: space-evenly;
        flex-direction: column;
        grid-gap: 25px;
      }
    }
    &-benifits {
      padding: 30px 0px;
      display: flex;
      flex-direction: column;
      .home-connector {
        width: 100%;
        align-self: center;
      }
    }
  }
  .motto-text {
    font-size: 34px;
    line-height: 44px;
    letter-spacing: -0.03em;
  }
  .benifit-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(6, minmax(0, 1fr));
    grid-row-gap: 24px;
    margin: 30px 0px;
    .grid-item {
      padding: 20px;
      grid-gap: 10px;
      .heading {
        font-size: 20px;
        line-height: 38px;
      }
      .sub {
        font-size: 14px;
      }
    }
  }
  .benifit-contact {
    margin: 30px 0px;
    .use__freeTrial {
      margin: 60px auto;
      padding: 0px 10px;
      max-width: 1280px;
      .freeTrial__textbox {
        text-align: center;
      }
    }
  }
  .client-testimonial {
    padding: 30px 0px;
    grid-gap: 30px;
  }
}
