.address-component {
  * {
    font-family: "Poppins";
  }
  background-color: #fff;
  padding: 25px;
  border-radius: 8px;
  max-width: 70vw;
  
  .heading {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #5e5e5e;
    span {
      color: red;
      margin-left: 2px;
    }
  }

  input,
  select {
    all: unset;
    background: #e2e8f0;
    border-radius: 7.94466px;
    padding: 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    option{
      white-space: nowrap;
    }
  }
  .button-container {
    margin: 20px 0px 0px 20px;
  }
}


.error-box{
  color: red;
  padding-left: 20px;
  font-size: 12px;
}