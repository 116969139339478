.open-engine-benchmarks {
  max-width: 80%;
  margin: auto;
  margin-top: 6rem;
  padding: 0;
  padding-top: 0.25rem;
  border-radius: 2em;
  background: linear-gradient(180deg, #0f0f0f -1.5%, rgba(0, 0, 0, 0) 21.15%);

  .comparison-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    margin-top: 3em;

    .comparison-item {
      padding: 1.5rem;
      border-radius: 2em;
      background: #0e0e0e;
      box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.12);
      display: flex;
      flex-direction: column;

      .title {
        font-size: 2rem;
        margin: 0;
        margin-bottom: 0.25em;
      }

      .content {
        display: flex;
        flex-direction: column;
        flex: 1 1 0%;

        .desc {
          color: #c0c0c0;
          font-size: 0.9rem;
          font-weight: 600;
          color: white;
          flex: 1 1 0%;
        }

        .img {
          max-height: 30px;
          width: auto;
          max-width: 100px;
          margin-right: auto;
        }
      }
    }
  }

  .comparison-graphs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 2em;

    .comparison-graph {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 3em;
    }
  }

  .benchmark-primary {
    color: #50ffff !important;
  }

  .benchmark-secondary {
    color: #4154fe !important;
  }
}

@include media("<=xl") {
  .open-engine-benchmarks {
    max-width: 90%;
    margin-top: 3rem;

    .comparison-items {
      grid-template-columns: 1fr 1fr;
      gap: 2rem;

      .comparison-item {
        display: grid;
        grid-template-columns: 37.5% auto;

        .title {
          align-self: self-start;
        }

        .content {
          desc {
            margin: 0;
            margin-bottom: 0.75rem;
            font-weight: 500;
          }
        }
      }
    }

    .comparison-graphs {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@include media("<=medium") {
  .open-engine-benchmarks {
    border-radius: 8px;
    .comparison-items {
      grid-template-columns: 1fr;
      .comparison-item {
        border-radius: 8px;
      }
    }

    .comparison-graphs {
      grid-template-columns: 1fr;
      .comparison-graph {
        border-radius: 1.5em;
      }
    }
  }
}
