.header-container {
  max-width: 1280px;
}

.site-header {
  position: absolute !important;
  top: 0;
  width: 100%;
  z-index: 10 !important;
  background: color-bg(header);

  + .site-content {
    .section:first-of-type {
      padding-top: $header-height__mobile;
    }
  }

  .brand {
    margin-right: $header-nav--padding-h;
  }
}

.site-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header-height__mobile;

  .header-nav {
    display: flex;
    align-items: center;

    .header-nav-inner {
      display: flex;
      flex-grow: 1;
      gap: 1rem;
      .dropdown-wrapper {
        position: relative;
      }
      .header-dropdown {
        font-family: "Poppins";
        z-index: 1;
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translateX(-50%);
        display: none;
        // overflow: auto;
        padding: 6px;
        background: #252836;
        box-shadow: 0px 24px 32px 4px rgba(167, 174, 186, 0.12);
        border-radius: 16px;
        &::after {
          content: "";
          display: block;
          position: absolute;
          background: #252836;
          border-radius: 2px;
          top: -10px;
          left: 50%;
          transform: matrix(0.75, -0.66, 0.75, 0.66, 0, 0) translateX(-50%);
          height: 16px;
          width: 16px;
        }
        .product-box {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          grid-gap: 16px;
          padding: 16px;
        }
        .product {
          &-tabs {
            display: flex;
            width: 100%;
            .selected {
              border-bottom: 1px solid rgb(9, 155, 236);
              color: rgb(9, 155, 236);
              transition: all 0.1s;
            }
          }
          &-tab {
            flex: 1;
            text-align: center;
            color: #fff;
            font-size: 14px;
            font-weight: 800;
            cursor: pointer;
            border-bottom: 1px solid transparent;
          }
        }
        .feature-box {
          background: #151722;
          border-radius: 16px;
          width: 320px;
        }
        .heading {
          font-weight: 600;
          font-size: 12px;
          line-height: 150%;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: #90ecf4;
        }
        .item {
          display: flex;
          align-items: flex-start;
          grid-gap: 16px;
          cursor: pointer;
          &:hover {
            .product-details {
              span {
                color: #7a7ad4f3;
              }
            }
          }
          .box {
            width: 24px;
            height: 24px;
            background: #6061f6;
            border-radius: 8px;
          }
          .product-details {
            display: flex;
            flex-direction: column;
            grid-gap: 3px;
            span {
              line-height: 150%;
              color: #ffffff;
              text-align: left;
            }
            .name {
              font-weight: 600;
              font-size: 14px;
            }
            .name-1 {
              font-size: 14px;
              font-weight: 400;
              align-self: center;
            }
            .detail {
              font-weight: 500;
              font-size: 12px;
            }
          }
        }
        &::-webkit-scrollbar {
          background-color: transparent;
          width: 6px;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.4);
          border-radius: 8px;
        }
        ul {
          padding: 0px;
          list-style: none;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #ffffff;
          flex-direction: column;
          align-items: flex-start;
          a {
            all: unset;
            color: #fff !important;
            &:hover {
              color: #6068db !important;
            }
          }
          li {
            padding: 8px 6px;
            cursor: pointer;
          }
        }
      }
    }

    .header-nav-inner {
      display: flex;
      flex-grow: 1;
      gap: 1rem;
      .dropdown-wrapper {
        position: relative;
      }
      .header-dropdown {
        font-family: "Poppins";
        z-index: 1;
        position: absolute;
        width: max-content;
        top: 80%;
        left: 50%;
        transform: translateX(-50%);
        display: none;
        // overflow: auto;
        padding: 8px;
        border-radius: 8px;
        background: #1f1f1f;
        &::after {
          content: "";
          display: block;
          position: absolute;
          border-radius: 2px;
          top: -10px;
          left: 50%;
          background: #1f1f1f;
          transform: matrix(0.75, -0.66, 0.75, 0.66, 0, 0) translateX(-50%);
          height: 16px;
          width: 16px;
        }
        &-item {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 8px 16px;
          grid-gap: 12px;
          color: #5b5b5b;
          font-family: Poppins;
          font-size: 15px;
          font-weight: 500;
          .active-img {
            display: none;
          }
        }
        &-item:hover {
          border-radius: 12px;
          background: #272727;
          color: #fff;
          .inactive-img {
            display: none;
          }
          .active-img {
            display: block;
          }
        }
      }
    }

    ul {
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin-bottom: 0;

      &:first-of-type {
        flex-wrap: wrap;
      }
    }
    .header-btns {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 12px;
      img{
        max-width: 42px;
      }
    }

    li {
      position: relative;
      display: flex;
      .invert-chevron {
        transition: 0.3s all;
        path {
          opacity: 0.8;
          stroke: #9ca9b3;
        }
      }
      &:hover {
        .dropdown-wrapper {
          padding-top: 0px;
          position: absolute;
          height: 200%;
          width: 100%;
        }
        .header-dropdown {
          display: block;
        }
        .invert-chevron {
          transform: rotate(180deg);
        }
      }
      + .header-button {
        margin-left: $header-nav--padding-h;
      }
    }

    a:not(.button) {
      display: block;
      @include anchor-aspect(header);
      @include font-weight(header-link);
      text-transform: $link-header--transform;
      padding: 0 $header-nav--padding-h;

      .invert-color & {
        @include anchor-aspect(header, inverse);
      }
    }

    .home-header {
      a {
        @include anchor-aspect(header, false, true);
        transition: 0.4s all;
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      }
      li {
        path {
          stroke: rgba(18, 17, 39, 0.72);
          opacity: 0.8;
        }
      }
    }

    a.button {
      margin-left: $header-nav--padding-h;
    }
  }
}
.z-index {
  z-index: 10;
}

.absolute {
  position: absolute;
  width: 100%;
}

.header-nav-center:first-of-type {
  flex-grow: 1;
  justify-content: flex-end;
}

.header-nav-right {
  justify-content: space-between;
  gap: 0.789rem;
  flex-grow: 0;

  + .header-nav-right {
    flex-grow: 0;
  }
}

.header-nav-toggle {
  display: none;
}

@include media("<=xl") {
  .site-header-inner,
  .header-nav-inner {
    justify-content: center;
    // flex-wrap: wrap;
    margin-top: 0.5rem;
  }

  .header-nav-inner {
    gap: 0.75rem !important;
  }

  .site-header-inner .header-nav .header-btns {
    margin-left: unset;
  }
}

@include media("<=medium") {
  .site-header-inner {
    justify-content: space-between;
  }

  .header-nav-toggle {
    display: block;
    z-index: 10;
    // Header navigation when the hamburger is a previous sibling
    + .header-nav {
      position: absolute;
      transition: 0.4s opacity;
      display: none;
      z-index: 3;
      top: 50px;
      left: 100vw;
      opacity: 0;
      &.is-active {
        opacity: 1;
        display: block;
        left: 0vw;
      }
      .home-header {
        a {
          @include anchor-aspect(header);
          @include font-weight(header-link);
          transition: 0.4s all;
          opacity: 0.8;
          &:hover {
            opacity: 1;
          }
        }
      }

      .header-nav-inner {
        flex-direction: column;
        padding: $header-nav--padding-v__mobile;
        position: fixed;
        justify-content: center;
        align-items: center;
        inset: 0;
        background: #000;
        margin-top: unset;
      }

      ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
        grid-gap: 20px;
        a:not(.button) {
          display: inline-flex;
          @include anchor-aspect(header-mobile);
          padding: 0px 12px;
          padding-top: $header-nav--padding-v__mobile * 0.5;
          padding-bottom: $header-nav--padding-v__mobile * 0.5;
        }
      }

      a.button {
        margin-left: 0;
        margin-top: $header-nav--padding-v__mobile * 0.5;
        margin-bottom: $header-nav--padding-v__mobile * 0.5;
      }

      .header-btns {
        margin-left: 0;
        flex-direction: column;
        gap: 1.5rem;

        border-radius: 0.5em;
        display: flex;
        justify-content: center;
        text-align: center;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        .button__login {
          color: white;
          border: 1px solid white;
          width: 100%;
          &:hover {
            background: #444bd3;
            border: 1px solid #444bd3;
          }
        }

        .button__login-secondary,
        .button__signup,
        .button__signup-secondary,
        .button__join-waitlist {
          width: 100%;
        }
      }
    }
  }
}

@include media(">medium") {
  .site-header {
    + .site-content {
      .section:first-of-type {
        padding-top: $header-height__desktop;
      }
    }
  }

  .site-header-inner {
    height: $header-height__desktop;
  }
}

.header-tooltip {
  position: absolute;
  top: -12px;
  right: -20px;
  font-weight: bolder;
}
