.deploy {
  &-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 60px;
    margin-top: 30px;
    * {
      font-family: "Poppins";
    }

    .sandbox-cred {
      .credentials {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .cred-name {
          display: flex;
          align-items: center;
          grid-gap: 7px;
          margin-bottom: 14px;
          cursor: pointer;
          span {
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
          }
        }
        .cred-value {
          padding-left: 22px;
          font-weight: 400;
          margin-bottom: 30px;
          a {
            text-decoration-line: underline;
            color: #4c73e9;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
      .expiry-box {
        background: #151722;
        color: #fff;
        border-radius: 12px;
        padding: 20px 40px;
        min-width: 550px;
        display: flex;
        flex-direction: column;
        grid-gap: 16px;
        .heading {
          font-weight: 600;
          font-size: 12px;
          line-height: 150%;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: #ffffff;
        }
        .details {
          display: flex;
          grid-gap: 20px;
          align-items: center;
          .number-value {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 45px;
            width: 45px;
            background: #407bff;
            border-radius: 8px;
          }
          .details-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .text-main {
              font-weight: 600;
              font-size: 14px;
              line-height: 150%;
            }
            .text-sec {
              font-weight: 300;
              font-size: 12px;
              line-height: 150%;
            }
          }
        }
      }
    }

    .sanbox-instruction {
      display: flex;
      flex-direction: column;
      padding: 40px 30px;
      height: fit-content;
      flex: 3;
      flex-shrink: 0;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
      .heading {
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        color: #000000;
      }
      .subHeading {
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        mix-blend-mode: normal;
        opacity: 0.4;
        margin: 10px 0px 50px 0px;
      }
      .heading-2 {
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 10px;
      }
      .list {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
        a {
          text-decoration-line: underline;
          color: #4c73e9;
        }
      }
      .instruction-cta {
        display: flex;
        align-items: center;
        grid-gap: 25px;
      }
    }
    .option {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      flex: 2;
      min-width: 400px;
      max-width: 400px;
      .top {
        background: #f4f4f4;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px 0px;
        img {
          max-height: 8rem;
        }
      }
      .deploy-detail {
        display: flex;
        flex-direction: column;
        padding: 25px;
        align-items: flex-start;
        .heading {
          font-weight: 600;
          font-size: 22px;
          line-height: 28px;
          color: rgba(0, 0, 0, 0.8);
          margin-bottom: 24px;
        }
        .subHeading {
          font-weight: 400;
          font-size: 16px;
          line-height: 27px;
          color: #828282;
          max-width: 400px;
          margin-bottom: 40px;
        }
        button {
          align-self: center;
        }
      }
    }
  }
}

@include media("<=medium") {
  .deploy {
    &-options {
      flex-direction: column;
      grid-gap: 30px;
      margin-top: 0px;
      .option {
        flex: 1;
        min-width: 0;
        max-width: 360px;
        .top {
          padding: 30px 0px;
          img {
            max-height: 5rem;
          }
        }
        .deploy-detail {
          padding: 20px;
          .heading {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 20px;
          }
          .subHeading {
            font-size: 14px;
            line-height: 24px;
            max-width: 400px;
            margin-bottom: 20px;
          }
          button {
            align-self: center;
            font-size: 14px;
          }
        }
      }
      .sanbox-instruction {
        padding: 25px 20px;
        flex: 1;
        .heading {
          font-size: 18px;
        }
        .subHeading {
          margin: 10px 0px 25px 0px;
        }
        .list {
          font-size: 12px;
          grid-gap: 8px;
        }
        .instruction-cta {
          display: flex;
          flex-direction: column;
          position: relative;
          grid-gap: 20px;
          width: 100%;
          padding: 0px 25px;
          align-items: stretch;
          & > button {
            font-size: 14px;
            width: auto;
          }
        }
      }
      .sandbox-cred {
        .credentials {
          .cred-name {
            span {
              font-size: 14px;
            }
          }
          .cred-value {
            padding-left: 22px;
            margin-bottom: 20px;
            font-size: 14px;
            word-wrap: break-word;
            a {
              font-size: 12px;
            }
          }
        }
        .expiry-box {
          padding: 15px;
          min-width: 0px;
          grid-gap: 12px;
          .details {
            display: flex;
            grid-gap: 10px;
            align-items: flex-start;
            .number-value {
              flex-shrink: 0;
              height: 40px;
              width: 40px;
            }
            .details-text {
              .text-sec {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}
