.app__info {
  padding: 30px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 3rem;
  * {
    font-family: "Poppins";
  }
  & > .heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    margin-bottom: 6px;
  }
  & > .subHeading {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    mix-blend-mode: normal;
    opacity: 0.4;
    margin-bottom: 40px;
  }
  .info-box {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 1rem;
    margin-bottom: 40px;
    .item {
      display: flex;
      grid-gap: 8px;
      .tooltip {
        img {
          margin-top: 3px;
        }
      }
      .details {
        display: flex;
        flex-direction: column;
        grid-gap: 8px;
        & > .heading {
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
        }
        & > .value {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          a {
            text-decoration-line: underline;
            color: #4c73e9;
          }
        }
      }
    }
  }
  .divider {
    height: 0px;
    border: 0.5px dashed #000000;
  }
  .domain-configuration {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
    & > .heading {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 10px;
    }
    & > .subHeading {
      max-width: 470px;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      mix-blend-mode: normal;
      opacity: 0.4;
      margin-bottom: 20px;
    }
  }
}
