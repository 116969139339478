.dropdown {
  display: flex;
  position: relative;
  width: 100%;
  &-button {
    all: unset;
    display: flex;
    cursor: pointer;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 8px 12px;
    border: 1px solid #495057;
    border-radius: 6px;
    z-index: 2;
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
      color: #ffffff;
      text-transform: uppercase;
    }
  }
  &:hover .options {
    height: auto;
    width: auto;
    width: 100%;

    opacity: 1;
    transform: translateY(42px);
  }

  .options {
    position: absolute;
    display: flex;
    flex-direction: column;
    border: 1px solid #495057;
    padding: 10px 20px;
    grid-gap: 10px;
    height: 0;
    width: 0;
    opacity: 0;
    overflow: hidden;
    transition: 0.2s transform;
    border-radius: 6px;
  }
  .checkbox-box {
    display: flex;
    grid-gap: 10px;
  }
  .checkbox-label {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #fcfcfd;
    text-transform: uppercase;
  }
}
