.embed-box {
  .top-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 80px 0px 0px 0px;
  }
  .heading-box {
    display: flex;
    flex-direction: column;
  }
  .divider{
    margin: 80px 0px;
  }
  .text-sub {
    font-weight: 700;
    font-size: 18px;
    line-height: 180%;
    color: rgba(255, 255, 255, 0.75);
  }
  .embed-reports{
    margin: 80px 0px;
    display: flex;
    flex-direction: column;
    grid-gap: 80px;
  }
  .embed_benifits{
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }
}


@include media("<=medium") {
    .embed-box{
    .top-box{
        padding: 40px 0px;
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 40px;
    }
    .divider{
        margin: 40px 0px;
    }
    .embed-reports{
        margin: 40px 0px;
        grid-gap: 40px;
    }
}
}