.awards {
  padding: $home--dekstop__spacing 0px;
  display: flex;
  flex-direction: column;
  grid-gap: 85px;
}

.award {
  &__content {
    display: flex;
    align-self: center;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 40px;
  }
  &-card {
    padding: 32px;
    width: 250px;
    height: 250px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 20px;
    background: rgba(255, 255, 255, 0.04);
    box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.08);
    border-radius: 12px;
    & > img {
      max-height: 5rem;
      object-fit: contain;
    }
    & > button {
      padding: 0px;
      font-weight: 600;
      font-size: 12px;
      line-height: 180%;
      text-decoration-line: underline;
      color: #599bf9;
      img {
        max-height: 14px;
        margin-left: 8px;
      }
    }
  }
}

@include media("<=medium") {
  .awards {
    padding: $home--mobile__spacing 0px;
    grid-gap: 26px;
  }
}
