.open-engine-hero {
  max-width: 80%;
  margin: auto;
  text-align: left;
  display: flex;
  gap: 5rem;
  align-items: center;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: left;

    .main {
      margin-bottom: 3.5em;
      display: flex;
      justify-content: left;
      align-items: center;

      .title {
        font-size: 4.25rem;
        font-weight: 300;
        line-height: 1;
        letter-spacing: -5px;
        color: white;
        position: relative;

        b {
          font-weight: 600;
        }
      }

      .tag {
        font-size: 0.8rem;
        font-weight: 300;
        color: white;
        position: absolute;
        right: 0;
        bottom: -1rem;
        letter-spacing: -0.5px;
      }
    }

    .subtitle {
      font-size: 1.15rem;
      color: white;
      font-weight: 600;
      line-height: 1.5em;

      span {
        color: #50ffff;
      }
    }
  }

  .intro-video {
    width: 100%;
  }

  .footer-btns {
    display: flex;
    align-items: stretch;
    margin: 2em 0;
    justify-content: left;
    gap: 1em;

    .waitlist-btn-container {
      .waitlist-btn {
        font-size: 0.8rem;
        padding: 0.25em 1.25em;
        height: 100%;
      }
    }

    .product-hunt-badge {
      max-width: 225px;

      a {
        display: inline-block;
      }
    }
  }
}

@include media("<=xl") {
  .open-engine-hero {
    max-width: 90%;
    padding-bottom: 1rem;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .main {
        margin-bottom: 3em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 2rem;

        .title {
          font-size: 5rem;

          b {
            font-weight: 600;
          }
        }

        .tag {
          font-size: 0.8rem;
        }
      }

      .subtitle {
        font-size: 1.5rem;
      }
    }

    .intro-video {
      .video {
        .video-box {
          .content {
            .title {
              font-size: 2.5rem !important;
            }
          }
        }
      }
    }

    .footer-btns {
      margin: 2rem auto 0 auto;

      .waitlist-btn-container {
        .waitlist-btn {
          font-size: 1rem;
        }
      }
    }
  }
}

@include media("<=medium") {
  .open-engine-hero {
    .content {
      .main {
        .title {
          font-size: 3.25rem;
        }

        .tag {
          font-size: 0.6rem;
        }
      }

      .subtitle {
        font-size: 1rem;
      }
    }

    .intro-video {
      .video {
        .video-box {
          .content {
            .title {
              font-size: 1.5rem !important;
            }
          }
        }
      }
    }

    .footer-btns {
      .waitlist-btn-container {
        .waitlist-btn {
          font-size: 0.8rem;
          padding: 0.25em 1.25em;
        }
      }

      .product-hunt-badge {
        max-width: 175px;
      }
    }
  }
}
