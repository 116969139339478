.flow {
  &__box {
    & > div {
      display: flex;
      padding: $home--dekstop__spacing 0px;
      flex-direction: column;
      align-items: center;
    }
  }
  &__heading{
    margin-bottom: 8px;
  }
}

.flow__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  &-box {
    display: flex;
    align-self: stretch;
    padding: 40px 0px;
    margin-top: 55px;
    justify-content: space-around;
    background: #18294eab;
    box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.08);
    border-radius: 12px;
  }
  .content {
    &__heading {
      font-weight: 700;
      font-size: 40px;
      line-height: 140%;
      text-align: center;
      color: #ffffff;
    }
    &__sub {
    max-width: 210px;
      font-weight: 600;
      font-size: 14px;
      line-height: 180%;
      text-align: center;
      color: rgba(255, 255, 255, 0.56);
    }
  }
}


@include media("<=medium"){

  .flow{
    &__box{
      &> div{
        padding: $home--mobile__spacing 0px;
        max-width: 90%;
      }
    }
  }
  .flow__content{
    &-box{
      flex-direction: column;
      margin-top: 40px;
      grid-gap: 50px;
    }
    .content{
      &__sub{
        font-size: 12px;
      }
    }
  }

}