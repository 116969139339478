.features {
  &__box {
    padding: $home--dekstop__spacing 0px;
    & > div {
      display: flex;
      padding-left: 0;
        max-width: 75%;
      .left {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        img {
          object-fit: contain;
        }
      }
      .right {
        flex: 1;
      }
    }
  }
  &__tag {
    margin-bottom: 10px;
  }
  &__heading {
    margin-bottom: 32px;
  }
  &__powered {
    display: flex;
    margin: 34px 0px;
    align-items: center;
    font-size: 10.6387px;
    line-height: 182%;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    img {
      margin-left: 10px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    grid-gap: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    color: #ffffff;

    .item {
      display: flex;
      img {
        margin-right: 13px;
      }
    }
  }
  &__divider {
    margin: 28px 0px;
  }
}

.flex-start {
  justify-content: flex-start !important;
}

@include media("<=medium") {
  .features {
    &__box {
      padding: $home--mobile__spacing 0px;
      & > div {
        max-width: 90%;
        padding: 0px;
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 50px;
      }
    }
    &__content {
      grid-gap: 14px;
      font-size: 10px;
    }
    &__divider {
      margin: 28px 0px;
    }
    &__powered {
      margin-bottom: 10px;
    }
  }
}
