.payment-details {
  flex: 1;
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  color: #000;
  width: 70vw;
  max-width: 50rem;
  * {
    font-family: "Poppins";
  }
  .item-heading {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }
}

.payment {
  &-heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    margin-bottom: 6px;
  }
  &-sub {
    font-size: 14px;
    line-height: 20px;
    mix-blend-mode: normal;
    opacity: 0.4;
    margin-bottom: 40px;
  }
}

.subscription {
  &-heading {
    &::before {
      content: url("../../../images/arrow-right-tilted.svg");
      position: relative;
      top: 6px;
      left: -5px;
    }
  }
  &-container {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }

  &-details {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    .detail-row {
      display: flex;
      align-items: center;
      line-height: 21px;
      grid-gap: 20px;
      .detail {
        &-heading {
          font-weight: 500;
          font-size: 14px;
          width: 150px;
          word-wrap: break-word;
        }
        &-item {
          font-size: 14px;
          width: 80px;
        }
      }
    }
  }
}

.detail-button {
  padding: 6px 12px;
  font-size: 14px;
}

.tablelist {
  &-container {
    background: #ffffff;
    border: 1px solid #cfcfcf;
    border-radius: 16px;
    .list-top {
      display: flex;
      align-items: center;
      padding: 26px 34px;
      border-bottom: 1px solid #cfcfcf;
      button {
        margin-left: auto;
      }
    }
    .list-bottom {
      padding-top: 26px;
    }
  }
  &-heading {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    img {
      margin-right: 15px;
    }
  }
}

.list-table {
  margin-bottom: 0px;
  tr {
    position: relative;
    display: flex;
    border-bottom: none;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    padding-left: 80px;
    &:not(:last-of-type) {
      border-bottom: 1px solid #cfcfcf;
    }
  }
  td {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-align: center;
  }
  th {
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5e5e5e;
    flex: 1;
  }
  .data-tick {
    &::before {
      content: url("../../../images/green-tick.svg");
      position: absolute;
      top: 50%;
      left: 30px;
      transform: translateY(-50%);
    }
  }
}

.billing {
  &-details {
  }
  &-heading {
    margin-bottom: 24px;
    &::before {
      content: url("../../../images/globe.svg");
      position: relative;
      top: 6px;
      left: -5px;
    }
  }
}

.card {
  &-details {
  }
  &-heading {
    margin-bottom: 24px;
    &::before {
      content: url("../../../images/credit-card.svg");
      position: relative;
      top: 6px;
      left: -5px;
    }
  }
}

.stripe-payment-container {
  input,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    // max-width: 500px;
    padding: 10px 14px;
    background: #e2e8f0;
    border-radius: 7.94466px;
    padding: 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
  }
}

.card__container {
  position: relative;
  max-width: 550px;
  background-color: #fff;
  padding: 20px !important;
  border-radius: 8px;
  .stripe-logo{
    position: absolute;
    right: 20px;
    top: -20px;
    img{
      max-height: 100px;
      
      
    }
  }
  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #5e5e5e;
    span {
      color: red;
      margin-left: 2px;
    }
  }
  .heading {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .card-error {
    min-height: 20px;
    color: red;
    font-size: 14px;
  }
  .stripe-button {
    img {
      max-height: 25px;
    }
  }
}

.no-card {
  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-text {
    font-size: 14px;
    line-height: 21px;
  }
}
