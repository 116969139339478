.section-header {
  text-align: center;
  margin: auto;
  padding: 0;
  margin-top: 6rem;
  display: flex;
  flex-direction: column;

  .subtitle {
    line-height: unset;
    width: fit-content;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 3.2px;
    border-radius: 12px;
    background: linear-gradient(91deg, #4154fe 0.46%, #7163f8 98.67%);
    margin: auto;
    padding: 0.3em 2em;
    text-transform: uppercase;
  }

  .title {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.25em;
    letter-spacing: -1.5px;
    text-align: center;
    margin-top: 1em;
    color: white;
  }

  .desc {
    color: #c0c0c0;
    margin: 1.5em auto;
  }
}

@include media("<=xl") {
  .section-header {
    max-width: 90%;
    margin-top: 2rem;

    .title {
      font-size: 1.75rem;
      margin-top: 1em;
      margin-top: 2rem;
    }

    .desc {
      font-size: 0.9rem;
      margin-top: 1em;
    }
  }
}

@include media("<=medium") {
  .section-header {
    .title {
      font-size: 1.5rem;
    }
  }
}
