.connector {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  color: white;
  * {
    font-family: "Poppins";
  }
  &__heading {
    text-align: center;
    font-weight: 700;
    font-size: 56px;
    line-height: 120%;
    width: 65%;
    margin-bottom: 10px;
  }
  &__sub {
    font-weight: 400;
    font-size: 20px;
    line-height: 180%;
    text-align: center;
    width: 60%;
    margin-bottom: 50px;
    &-purple {
      color: #6061f6;
    }
  }
  &__ellipse {
    position: absolute;
    right: 0rem;
    top: 20rem;
    width: 393.91px;
    height: 193px;
    background: rgba(211, 0, 203, 0.4);
    opacity: 0.5;
    filter: blur(200px);
    transform: rotate(-65.13deg);
  }
  &__cta {
    display: flex;
    align-items: center;
    grid-gap: 25px;
    margin-bottom: 60px;
    &-btn {
      border-radius: 8px;
      z-index: 1;
      &1 {
        background: #444bd3;
        &:hover {
          background: #4d52b2;
        }
      }
      &2 {
        background: transparent;
        border: 1px solid #281d27;
        &:hover {
          background: transparent;
        }
      }
    }
  }
  .connector-divider {
    display: flex;
    align-self: stretch;
    align-items: center;
    margin-bottom: 50px;
    &-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-right: 20px;
    }
    &-line {
      flex: 1;
      border: 2px solid #495057;
    }
  }
  &__search {
    border: 2px solid #495057;
    z-index: 1;
    border-radius: 20px;
    width: 80%;
    display: flex;
    align-items: center;
    padding: 6px 6px 6px 20px;
    margin-bottom: 70px;
    .search-input {
      background-color: transparent;
      padding: 10px 20px;
      border: none;
      width: 100%;
      color: white;
      &:focus {
        outline: none;
      }
    }
  }
  &__request {
    display: flex;
    margin-top: 80px;
    grid-gap: 20px;
    width: 100%;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 12px;
    padding: 32px 24px;
    .question {
      padding: 11px 14px;
      background: #67e4ef;
      align-self: flex-start;
      border-radius: 50%;
    }
    .request {
      display: flex;
      flex-direction: column;
      &__heading {
        font-weight: 700;
        font-size: 40px;
        line-height: 140%;
        margin-bottom: 12px;
      }
      &__sub {
        font-weight: 400;
        font-size: 14px;
        line-height: 180%;
        color: rgba(255, 255, 255, 0.56);
        margin-bottom: 12px;
      }
      &__cta {
        color: #599bf9;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
        img {
          height: 14px;
          transform: rotate(90deg);
          margin-left: 15px;
        }
      }
    }
  }
}

.sources__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 25px;
    margin-bottom: 80px;
  }
  &-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    & > button {
      order: 0;
      width: auto;
    }
    & > * {
      margin-left: auto;
    }
  }
}

.source__card {
  position: relative;
  min-width: 250px;
  min-height: 250px;
  background: rgba(255, 255, 255, 0.04);
  box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.08);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.5s all;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid rgba(68, 75, 211, 0.3);
    box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.1);
    .source__cta {
      opacity: 1;
    }
  }
  &-imgcont {
    width: 84px;
    height: 84px;
    // background: rgba(217, 217, 217, 0.25);
    // border: 7.66837px solid rgba(181, 181, 181, 0);
    border-radius: 50%;
    margin-bottom: 25px;
    margin-top: 3rem;
    .wrapper {
      background: rgba(16, 170, 80, 0.25);
      box-shadow: 0px 1.852px 1.852px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
  &-name {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    margin-bottom: 10px;
  }
  .source__cta {
    display: flex;
    align-items: center;
    opacity: 0;
    transition: 0.5s all;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #6068db;
    cursor: pointer;
    img {
      margin-left: 7px;
    }
  }
  &-alpha {
    background: rgba(255, 216, 159, 0.1);
    border-radius: 100px;
    font-weight: 300;
    font-size: 12px;
    line-height: 180%;
    text-align: center;
    color: #ffbb24;
    padding: 4px 20px;
    position: absolute;
    top: 20px;
    left: 15px;
  }
}

@include media("<=medium") {
  .connector {
    &__heading {
      font-size: 32px;
      line-height: 140%;
      width: 90%;
      margin-bottom: 12px;
    }
    &__sub {
      font-size: 16px;
      line-height: 24px;
      width: 75%;
      margin-bottom: 24px;
    }
    &__cta {
      flex-direction: column;
      align-self: stretch;
      margin-bottom: 28px;
      &-btn {
        width: 85%;
        &1 {
          order: 2;
        }
      }
    }
    &__search {
      width: 85%;
      margin-bottom: 28px;
      .search-input {
        font-size: 14px;
      }
    }
    &__request {
      .request {
        &__heading {
          font-size: 16px;
          line-height: 140%;
        }
      }
    }
  }
}
