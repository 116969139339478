.logo-img {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.dashboard-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  * {
    font-family: "Poppins";
  }
  .dash {
    &-img {
      height: 3rem;
    }
    &-tag {
      font-weight: 600;
      font-size: 14px;
      line-height: 170%;
      /* identical to box height, or 24px */
      letter-spacing: 0.8px;
      text-align: center;
      text-transform: uppercase;
      color: #6068db;
    }
    &-heading {
      font-weight: 500;
      font-size: 40px;
      line-height: 60px;
      /* identical to box height */

      text-align: center;

      /* Colors / Black / 100% */

      color: #000000;
    }
    &-sub {
      font-weight: 400;
      max-width: 500px;
      font-size: 14px;
      line-height: 180%;
      /* or 25px */

      text-align: center;

      color: #000000;

      mix-blend-mode: normal;
      opacity: 0.4;
    }
  }
}

.details__box {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: #fff;
  color: #000000;
  margin-bottom: 50px;
  .heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
  }
  .subHeading {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    mix-blend-mode: normal;
    opacity: 0.4;
    margin-bottom: 10px;
  }
  .form {
    width: 40rem;
  }
  .details-cta {
    margin-top: 2rem;
    display: flex;
    grid-gap: 20px;
  }
  .toggle_ingestion {
    position: absolute;
    right: 30px;
    display: flex;
    align-items: center;
  }
}

.dashboard-menu {
  .selected {
    color: #6068db;
    background-color: #eff0ff;
  }
  display: flex;
  flex-direction: column;
  & > div {
    cursor: pointer;
    padding: 15px;
    padding-left: 20px;
    font-size: 16px;
  }
  .divider {
    height: 1px;
    padding: 0px;
    background: #e4e4e4;
    border: none;
  }
}

.welcome-dashboard {
  * {
    font-family: "Poppins";
  }
  display: flex;
  width: 80vw;
  border-radius: 10px;
  background-color: #fff;
  ul {
    padding: 0px;
    padding-left: 12px;
    margin: 0px;
    a {
      color: #000;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .circles {
    display: flex;
    grid-gap: 15px;
    & > div {
      height: 12px;
      border-radius: 50%;
      width: 12px;
      background-color: #8bb7f0;
      border: 2px solid #437ab5;
    }
  }
  .welcome {
    &-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 20px;
      padding: 50px;
      flex: 5;
      .welcome-check {
        display: flex;
        align-items: center;
        grid-gap: 6px;
        label {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
    &-right {
      flex: 4;
      background-color: #000;
      border-radius: 0px 10px 10px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .fruits {
        display: flex;
        align-items: center;
        grid-gap: 30px;
        margin-top: 90px;
        img {
          max-width: 50px;
        }
      }

      .quote-text {
        text-align: center;
        margin: 40px 0px;
        span {
          font-weight: 300;
          font-size: 28px;
          line-height: 41px;
          color: #ffffff;
        }
      }
    }
  }
  .heading-1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #000;
  }
  .heading-2 {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .sub-heading {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    max-width: 80%;
  }
  .text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
}

@include media("<=medium") {
  .dzip-logo-mobile {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    img {
      max-width: 6rem;
    }
  }
  .dashboard-box {
    height: 0px;
    width: 0px;
    overflow: hidden;
    margin-top: 3rem;
    margin-bottom: 15px;
    .dash {
      &-img {
        height: 2.5rem;
      }
      &-tag {
        font-size: 12px;
      }
      &-heading {
        font-size: 21px;
        line-height: 48px;
      }
    }
  }
  .details__box {
    padding: 15px;
    .heading {
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 8px;
    }
    .subHeading {
      font-size: 12px;
      margin-bottom: 15px;
    }
    .form {
      width: auto;
    }
    .details-cta {
      margin-top: 1rem;
      grid-gap: 15px;
    }
    .toggle_ingestion {
      right: 6px;
      top: 8px;
      display: flex;
      align-items: center;
      span {
        font-size: 12px;
      }
    }
  }
  .welcome-dashboard {
    flex-direction: column;
    width: auto;
    .welcome {
      &-left {
        grid-gap: 15px;
        padding: 20px;
      }
      &-right {
        display: none;
      }
    }
    .heading-1 {
      font-weight: 600;
      font-size: 15px;
    }
    .heading-2 {
      font-size: 13px;
    }
    .sub-heading {
      font-size: 12px;
      max-width: 100%;
    }
    .text {
      font-size: 12px;
    }
  }
}
