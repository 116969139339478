.testimonial {
  padding: $home--dekstop__spacing 0px;
  max-width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tabs {
  display: flex;
  align-self: stretch;
  overflow: auto;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  &::-webkit-scrollbar {
    display: none;
  }
  .tab {
    padding: 25px;
    cursor: pointer;
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 200px;
      object-fit: contain;
    }
  }
  .selected {
    transition: 0.4s all;
    border-bottom: 1px solid #6068db;
  }
}

.tab-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // height: 18rem;
  grid-gap: 45px;
  margin-top: 45px;
  &-message {
    font-weight: 400;
    font-size: 20px;
    line-height: 180%;
    text-align: center;
    color: #ffffff;
    max-width: 744px;
  }
  &-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 180%;
    text-align: center;
    color: #ffffff;
    img {
      margin-bottom: 15px;
      object-fit: contain;
      max-width: 100px;
      border-radius: 50%;
    }
    .position {
      color: #6068db;
    }
  }
}

@include media("<=medium") {
  .testimonial {
    max-width: 90%;
    padding: $home--mobile__spacing 0px;
  }
  .tabs {
    .tab {
      padding: 15px;
      img {
        max-width: 5rem;
      }
    }
  }
  .tab-content {
    &-message {
      font-size: 16px;
    }
  }
}
