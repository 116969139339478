.footer {
  & > div:nth-child(1) {
    padding: 85px 0px;
    display: flex;
    max-width: 80%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }
  & > div:nth-child(2) {
    margin: auto;
    text-align: center;
    padding: 40px 0px 0px 0px;
    font-size: 12px;
  }
  & > div:nth-child(3) {
    margin: auto;
    text-align: center;
    padding-bottom: 40px;
  }
  .left {
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    .footer-heading {
      max-width: 250px;
      font-weight: 400;
      font-size: 16px;
      line-height: 180%;
      color: rgba(255, 255, 255, 0.56);
    }
  }

  .company-address {
    font-size: 12px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.56);
    text-align: center;
  }
  .right {
    flex: 1;
    display: flex;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 50px;
  }

  .types {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    &:first-of-type {
      margin-left: auto;
    }
    .type-heading {
      font-weight: 600;
      font-size: 14px;
      line-height: 170%;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.4);
    }
    ul {
      list-style: none;
      padding: 0px;
      li {
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        color: #ffffff;
        cursor: pointer;
        margin-bottom: 10px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.footer-social > ul {
  display: flex;
  grid-gap: 30px;
  align-items: center;
}

@include media("<=medium") {
  .footer {
    & > div:nth-child(1) {
      padding: 85px 0px;
      flex-direction: column;
      max-width: 80%;
    }
    .left {
      padding: 0px;
    }
    .right {
      grid-gap: 30px;
      grid-template-columns: repeat(2, 1fr);
    }

    .types {
      flex-direction: column;
      grid-gap: 20px;
    }
  }
}
