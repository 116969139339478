.product__layout {
  .landing__box {
    padding-top: 3rem;
    .product-image {
      max-width: 18rem;
    }
  }
  .benifit {
    &-heading {
      text-align: center;
      padding: 30px 0px;
      margin: auto;
      font-size: 46px;
      font-weight: 700;
      line-height: 58px;
      letter-spacing: -2px;
      background: var(
        --linear-gradient,
        linear-gradient(180deg, #f6f6f7 0%, #7e808f 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .details__wrapper {
    display: flex;
    flex-direction: column;
    padding: 85px 0px;
    grid-gap: 50px;
  }
}

.product {
  &__details {
    display: flex;
    padding-right: 35px;
    align-self: center;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 15px;
    flex: 3;
    .heading {
      color: var(--neutral-white-200, #f6f6f7);
      font-size: 32px;
      font-weight: 700;
      line-height: 40px; /* 125% */
      letter-spacing: -0.96px;
    }
    .subtext {
      color: var(--neutral-gray-300, #8f9bb7);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    .cta {
      all: unset;
      cursor: pointer;
      border-radius: 46px;
      gap: 15px;
      margin-top: 30px;
      font-size: 16px;
      color: #f6f6f7;
      display: flex;
      padding: 15px 25px;
      justify-content: center;
      align-items: center;
      border: 1.2px solid #282d45;
      background: linear-gradient(
        216deg,
        rgba(21, 25, 52, 0.52) 0%,
        rgba(21, 25, 52, 0.08) 47.92%,
        rgba(21, 25, 52, 0.49) 100%
      );
      transition: 0.2s all;
      &:hover {
        border: 1.2px solid #5a5d6c;
      }
    }
  }
  &__card {
    display: flex;
    position: relative;
    padding: 70px;
    border-radius: 10px;
    border: 1px solid var(--neutral-gray-500, #282d45);
    background: var(--neutral-gray-800, #0e1330);
    .order {
      order: 1;
      padding: 0px 30px;
    }
  }
  &__card-image {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 14rem;
    }
  }
  &__elipse {
    &-1 {
      position: absolute;
      border: 10px solid red;
      right: 0px;
      top: 10px;
      width: 211px;
      height: 263px;
      border-radius: 263px;
      background: var(--secondary-summer-sky, #32cafd);
      filter: blur(250px);
    }
  }
}

@include media("<=medium") {
  .product__layout {
    .landing__box {
      padding-top: 2rem;
      .product-image {
        max-width: 10rem;
      }
    }
    .benifit {
      &-heading {
        padding: 15px 0px;
        font-size: 30px;
        line-height: 38px;
      }
    }
    .details__wrapper {
      padding: 30px 0px;
      grid-gap: 30px;
    }
  }

  .product {
    &__details {
      padding-right: 0px;
      padding-bottom: 30px;
      .heading {
        font-size: 22px;
        line-height: 28px; /* 125% */
      }
      .subtext {
        font-size: 14px;
        line-height: 20px; /* 150% */
      }
      .cta {
        margin-top: 20px;
        font-size: 14px;
      }
    }
    &__card {
      flex-direction: column;
      padding: 30px 20px;
      .order {
        padding: 0px;
        padding-top: 30px;
      }
    }
    &__card-image {
      img {
        max-width: 10rem;
      }
    }
  }
}
