.olake-product {
  &-container {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
  }
  &-heading {
    text-align: center;
    margin-top: 24px;
    font-size: 56px;
    font-weight: 500;
    color: #fff;

    &-type1 {
      font-style: italic;
      font-weight: 500;
      padding-right: 5px;
    }
    &-type2 {
      font-weight: 700;
      margin-left: -5px;
    }
    &-type3 {
      color: #fff;
      font-weight: 400;
      line-height: normal;
    }
  }

  &-architecture {
    margin-top: 46px;
    z-index: 1;
  }

  &-waitlist-button {
    margin-top: 32px;
    all: unset;
    cursor: pointer;
    display: flex;
    padding: 8px 48px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #193ae6;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px; /* 160% */
  }

  &-subheading {
    margin-top: 80px;
    max-width: 80%;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
    line-height: 48px; /* 200% */
    background: linear-gradient(93deg, #fff 0.36%, #bdbdbd 119.34%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-feature {
    border-radius: 8px;
    background: #202020;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(6),
    &:nth-child(7) {
      grid-column: span 2;
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      grid-column: span 2;
    }

    &-box {
      display: flex;
      width: 100%;
      max-width: 90%;
      //   align-self: stretch;
      flex-direction: column;
      align-items: center;
      margin-top: 80px;
    }
    &-heading {
      color: #fff;
      text-align: center;
      font-size: 44px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      &-type1 {
        background: linear-gradient(87deg, #a3b1f5 20.36%, #193ae6 81.79%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &-list {
      display: grid;
      margin-top: 80px;
      grid-template-columns: repeat(6, 1fr); /* 6 equal columns */
      grid-template-rows: auto; /* Adjust rows automatically */
      grid-gap: 33px;
      //   padding: 0px 40px;
    }
    &-inner-heading {
      color: #fff;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      margin-top: 28px;
    }
    &-subheading {
      margin-top: 15px;
      color: #8d8d8d;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
    }
  }

  &-form {
    &-container {
      display: flex;
      width: 100%;
      max-width: 90%;
      margin-top: 100px;
      background: url("../../../../assets/images/olake-banner-vector.svg"),
        linear-gradient(0deg, #202020 0%, #202020 100%),
        radial-gradient(
          169.05% 116.62% at 20.83% -12.91%,
          rgba(255, 255, 255, 0.3) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      background-blend-mode: normal, hard-light, normal;
      background-repeat: no-repeat;
      background-position: bottom left;
      &-left {
        flex: 1;
        flex-shrink: 0;
        padding-left: 50px !important;
        padding-top: 50px;
      }

      &-right {
        flex: 1;
        flex-shrink: 0;
        min-height: 300px;
        margin: 30px !important;
        padding: 40px !important;
        border-radius: 8px;
        border: 1px solid #f5f5f5;
        background: #fff;
      }
    }
    &-brand {
      display: flex;
      grid-gap: 10px;
      align-items: center;
      color: #fff;
      font-size: 24px;
      font-weight: 600;
    }
    &-heading {
      color: #fff;
      margin-top: 32px;
      font-size: 42px;
      font-style: normal;
      font-weight: 600;
      line-height: 56px; /* 133.333% */
    }
  }

  &-blog {
    padding: 32px;
    border-radius: 8px;
    background: #202020;
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 0 0;
    cursor: pointer;

    &-container {
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 90%;
    }
    &-main-heading {
      color: #fff;
      text-align: center;
      font-size: 42px;
      font-weight: 600;
      line-height: 52px; /* 125% */
      letter-spacing: -2.88px;
    }
    &-list {
      display: flex;
      grid-gap: 24px;
      margin-top: 60px;
    }
    &-image {
      object-fit: contain;
    }
    &-tag {
      margin-top: 24px;
      border-radius: 24px;
      align-self: flex-start;
      background: #333;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 12px;
      font-weight: 500;
      padding: 0px 12px;
    }
    &-heading {
      color: #fff;
      margin-top: 8px;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px; /* 133.333% */
      letter-spacing: -0.72px;
    }
    &-subheading {
      margin-top: 8px;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
    }
    &-link {
      display: flex;
      align-items:  center;
      grid-gap: 6px;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px; /* 100% */
    }
  }
  &-faq {
    margin-top: 80px;
    max-width: 90%;
  }
}

.benchmark {
  &-container {
    margin-top: 40px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    max-width: 90%;
    * {
      font-family: Poppins;
    }
  }
  &-heading {
    color: #fff;
    text-align: center;
    font-size: 54px;
    font-weight: 700;
    line-height: normal;
    &-type1 {
      font-weight: 400;
    }
  }
  &-subheading {
    color: #9e9e9e;
    text-align: center;
    line-height: normal;
    font-size: 14px;
    font-weight: 400;
    margin-top: 16px;
  }
  &-databox {
    border-radius: 12px;
    background: #171717;
    margin-top: 52px;
    padding: 36px 38px;
    display: flex;
    flex-direction: column;
    &-heading {
      display: flex;
      align-items: center;
      color: #407bff;
      font-size: 12px;
      grid-gap: 8px;
      line-height: normal;
    }
    &-tag {
      color: #fff;
      font-size: 32px;
      font-weight: 600;
      line-height: 56px; /* 175% */
    }
  }
  &-item {
    flex: 1;
    flex-shrink: 0;
    &-box {
      display: flex;
      align-items: stretch;
      grid-gap: 80px;
      margin-top: 26px;
    }
    &-number {
      color: #3e7aff;
      font-size: 48px;
      font-weight: 500;
      line-height: 40px; /* 83.333% */
    }
    &-heading {
      color: #fff;
      font-size: 24px;
      margin-top: 8px;
      font-weight: 600;
      line-height: 40px; /* 166.667% */
    }
    &-subheading {
      color: #e0e0e0;
      line-height: normal;
      color: #e0e0e0;
      font-size: 16px;
      margin-bottom: 24px;
    }
    &-graph {
      width: 100%;
      object-fit: contain;
    }
    &-conclusion {
      margin-top: 24px;
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      border-radius: 12px;
      border: 1px solid #2747ee;
      background: #222536;
      &-heading {
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        line-height: normal;
      }
      &-result {
        color: #407bff;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        .bold {
          font-weight: 600;
        }
      }
    }
    &-case {
      margin-top: 24px;
      color: #9e9e9e;
      font-size: 12px;
      line-height: normal;
    }
  }
}

.winning-edge {
  &-box {
    display: flex;
    width: 90%;
    flex-direction: column;
    border-radius: 20px;
    background: #193ae6;
    align-items: center;
    padding: 40px;
    margin-top: 40px;
  }
  &-heading {
    color: #fff;
    font-size: 48px;
    font-style: italic;
    line-height: normal;
    .bold {
      font-style: normal;
      font-weight: 600;
    }
  }
  &-list {
    margin-top: 40px;
    display: flex;
    align-items: stretch;
    grid-gap: 24px;
  }
  &-item {
    border-radius: 24px;
    flex: 1;
    background: #1433d7;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 10px;
    padding: 40px 24px;
    &-heading {
      color: #fff;
      font-size: 32px;
      font-weight: 600;
      line-height: normal;
    }
    &-text {
      color: #fff;
      font-size: 15px;
      font-style: italic;
      line-height: 18px; /* 160% */
    }
  }
  &-sub {
    margin-top: 40px;
    color: #fff;
    text-align: center;
    font-size: 34px;
    font-weight: 400;
    line-height: normal;
    max-width: 600px;
    .bold {
      font-weight: 600;
    }
  }
  &-cta {
    all: unset;
    cursor: pointer;
    border-radius: 4px;
    background: #fff;
    display: flex;
    width: 200px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 40px;
    color: #111 !important;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px; /* 160% */
    transition: 200ms linear;
    &:hover {
      background: #e6e6e6;
    }
  }
}

.potential {
  &-box {
    display: flex;
    flex-direction: column;
    max-width: 90%;
    margin-top: 80px;
    * {
      font-family: Poppins;
    }
  }
  &-heading {
    color: #fff;
    font-size: 40px;
    font-weight: 400;
    line-height: 50px;
    max-width: 800px;
    text-align: center;
    align-self: center;
    .bold {
      font-weight: 600;
    }
  }
  &-grid {
    display: grid;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    grid-template-columns: repeat(8, 1fr);
    margin-top: 50px;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 8px;
      background: #171717;
      padding: 24px;
      &-img {
        max-height: 90px;
        object-fit: contain;
      }
      &-heading {
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 28px;
      }
      &-sub {
        color: #c6c6c6;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        margin-top: 10px;
      }
      &-1 {
        grid-column-start: 1;
        grid-column-end: 4;
      }
      &-2 {
        grid-column-start: 4;
        grid-column-end: 9;
      }
      &-3 {
        grid-column-start: 1;
        grid-column-end: 5;
      }
      &-4 {
        grid-column-start: 5;
        grid-column-end: 9;
      }
    }
  }
}

.get-started {
  &-box {
    display: flex;
    flex-direction: column;
    max-width: 90%;
    margin-top: 80px;
    * {
      font-family: Poppins;
    }
  }
  &-bg-img {
    position: absolute;
    filter: blur(74px);
    bottom: 60%;
  }
  &-heading {
    background: linear-gradient(93deg, #fff 0.36%, #bdbdbd 119.34%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 48px;
    font-weight: 700;
    line-height: 80px;
    text-align: center;
    z-index: 1;
    .light {
      font-weight: 400;
    }
  }
  &-subheading {
    color: #fff;
    z-index: 1;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }
  &-list {
    display: flex;
    grid-gap: 27px;
    margin-top: 50px;
    position: relative;
  }
  &-item {
    flex: 1;
    z-index: 1;
    border-radius: 16px;
    background: #171717;
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-img {
      max-height: 120px;
      object-fit: contain;
    }
    &-tag {
      margin-top: 20px;
      display: flex;
      align-items: center;
      color: #407bff;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
    }
    &-heading {
      margin-top: 16px;
      background: linear-gradient(93deg, #fff 0.36%, #bdbdbd 119.34%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 28px;
      line-height: normal;
      .bold {
        font-weight: 600;
      }
    }
    &-subheading {
      margin-top: 8px;
      color: #c7c7c7;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &-cta {
      all: unset;
      cursor: pointer;
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      padding: 6px 16px;
      font-weight: 500;
      line-height: 32px; /* 160% */
      width: 80%;
      text-align: center;
      margin-top: 28px;
      border-radius: 4px;
      transition: 200ms linear;

      &-secondary {
        background: #242424;
        &:hover {
          background: #e6e6e6;
          color: #111;
        }
      }

      &-tertiary {
        cursor: auto;
        // &:hover {
        //   color: #fff;
        //   background: #202020;
        // }
      }

      &-primary {
        background: #193ae6;
        &:hover {
          background: #4862eb;
        }
      }
    }
  }
}

@include media("<=medium") {
  .olake-product {
    &-container {
      padding: 20px;
      * {
        font-family: Poppins !important;
      }
    }
    &-heading {
      margin-top: 16px;
      font-size: 24px;
      line-height: 40px;
    }

    &-architecture {
      margin-top: 30px;
      max-width: 320px;
    }

    &-waitlist-button {
      margin-top: 32px;
      padding: 4px 48px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px; /* 160% */
    }

    &-subheading {
      margin-top: 50px;
      max-width: 100%;
      font-size: 16px;
      line-height: 32px; /* 200% */
    }

    &-feature {
      padding: 20px;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(6),
      &:nth-child(7) {
        grid-column: span 1;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        grid-column: span 1;
      }

      &-box {
        max-width: none;
        margin-top: 50px;
      }
      &-heading {
        font-size: 24px;
      }

      &-list {
        margin-top: 40px;
        grid-template-columns: repeat(1, 1fr); /* 6 equal columns */
        grid-gap: 30px;
        //   padding: 0px 40px;
      }
      &-inner-heading {
        font-size: 16px;
        margin-top: 24px;
      }
      &-subheading {
        margin-top: 12px;
        font-size: 14px;
      }
    }

    &-form {
      &-container {
        max-width: none;
        margin-top: 50px;
        flex-direction: column;
        background: none, linear-gradient(0deg, #202020 0%, #202020 100%),
          radial-gradient(
            169.05% 116.62% at 20.83% -12.91%,
            rgba(255, 255, 255, 0.3) 0%,
            rgba(255, 255, 255, 0) 100%
          );
        &-left {
          padding: 20px !important;
        }

        &-right {
          margin: 20px !important;
          padding: 20px !important;
        }
      }
      &-brand {
        font-size: 20px;
        font-weight: 600;
        img {
          max-width: 24px;
        }
      }
      &-heading {
        margin-top: 12px;
        font-size: 24px;
        line-height: 32px; /* 133.333% */
      }
    }

    &-blog {
      padding: 20px;

      &-container {
        margin-top: 50px;
        max-width: none;
      }
      &-main-heading {
        font-size: 24px;
        line-height: 52px; /* 125% */
        letter-spacing: -1px;
      }
      &-list {
        grid-gap: 24px;
        margin-top: 24px;
        flex-direction: column;
      }
      &-heading {
        margin-top: 6px;
        font-size: 16px;
        line-height: 24px; /* 133.333% */
        letter-spacing: -0px;
      }
      &-subheading {
        margin-top: 4px;
        font-size: 12px;
        line-height: 22px; /* 137.5% */
      }
      &-link {
        font-size: 12px;
        line-height: 16px; /* 100% */
      }
    }
    &-faq {
      margin-top: 50px;
      max-width: none;
    }
  }

  .benchmark {
    &-container {
      padding: 20px;
      max-width: none;
      margin-top: 24px;
    }
    &-heading {
      font-size: 24px;
    }
    &-subheading {
      display: none;
    }
    &-databox {
      margin-top: 40px;
      padding: 20px;
      &-heading {
        font-size: 12px;
        grid-gap: 8px;
        align-self: center;
        margin-top: 10px;
      }
      &-tag {
        font-size: 24px;
        line-height: 40px;
        align-self: center;
        margin-top: 16px;
      }
    }
    &-item {
      flex: 1;
      flex-shrink: 0;
      &-box {
        flex-direction: column;
        grid-gap: 40px;
        margin-top: 26px;
      }
      &-number {
        font-size: 36px;
        line-height: 44px; /* 83.333% */
      }
      &-heading {
        font-size: 20px;
        line-height: 30px; /* 166.667% */
      }
      &-subheading {
        font-size: 14px;
        margin-top: 8px;
        margin-bottom: 20px;
      }
      &-graph {
        width: 100%;
      }
      &-conclusion {
        margin-top: 24px;
        padding: 12px;
        &-heading {
          color: #fff;
          font-size: 12px;
          font-weight: 600;
          line-height: normal;
        }
        &-result {
          font-size: 12px;
        }
      }
      &-case {
        margin-top: 20px;
        color: #9e9e9e;
        font-size: 11px;
        line-height: normal;
      }
    }
  }

  .potential {
    &-box {
      max-width: none;
      margin-top: 40px;
    }
    &-heading {
      font-size: 24px;
      line-height: 32px;
    }
    &-grid {
      margin-top: 40px;
      &-item {
        padding: 20px;
        &-img {
          max-height: 45px;
        }
        &-heading {
          font-size: 16px;
          margin-top: 20px;
        }
        &-sub {
          font-size: 12px;
          margin-top: 10px;
        }
        &-1 {
          grid-column-start: 1;
          grid-column-end: 9;
        }
        &-2 {
          grid-column-start: 1;
          grid-column-end: 9;
        }
        &-3 {
          grid-column-start: 1;
          grid-column-end: 9;
        }
        &-4 {
          grid-column-start: 1;
          grid-column-end: 9;
        }
      }
    }
  }

  .winning-edge {
    &-box {
      width: 100%;
      padding: 20px;
    }
    &-heading {
      font-size: 24px;
    }
    &-list {
      margin-top: 24px;
      flex-direction: column;
      grid-gap: 20px;
    }
    &-item {
      padding: 24px 20px;
      &-heading {
        font-size: 20px;
      }
      &-text {
        color: #fff;
        font-size: 12px;
        line-height: 16px; /* 160% */
      }
    }
    &-sub {
      margin-top: 24px;
      font-size: 24px;
    }
    &-cta {
      padding: 10px 24px;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
      font-size: 16px;
      line-height: 20px; /* 160% */
    }
  }

  .get-started {
    &-box {
      margin-top: 40px;
      max-width: none;
    }
    &-bg-img {
      position: absolute;
      filter: blur(24px);
      bottom: 60%;
      top: -15%
    }
    &-heading {
      font-size: 24px;
      line-height: 30px;
    }
    &-subheading {
      font-size: 14px;
      margin-top: 8px;
    }
    &-list {
      flex-direction: column;
      grid-gap: 24px;
      margin-top: 28px;
    }
    &-item {
      padding: 20px 16px;
      &-img {
        max-height: 90px;
      }
      &-tag {
        margin-top: 12px;
      }
      &-heading {
        margin-top: 8px;
        font-size: 22px;
      }
      &-subheading {
        margin-top: 8px;
        font-size: 12px;
      }
      &-cta {
        font-size: 14px;
        line-height: 28px; /* 160% */
        margin-top: 20px;
      }
    }
  }
}
