.home__box {
  & > .container {
    display: flex;
    flex-direction: column;
    grid-gap: 85px;
    max-width: 80%;
    padding-bottom: $home--dekstop__spacing;
  }

  .trial {
    &-box {
      display: flex;
      width: 100%;
      align-items: center;
      padding: $home--dekstop__spacing 0px;
    }
    &-content {
      display: flex;
      position: relative;
      flex-direction: column;
      flex: 1;
      .ellipse {
        position: absolute;
        width: 455.05px;
        height: 163.84px;
        background: linear-gradient(
          90deg,
          rgba(253, 101, 66, 0.2) 8.19%,
          rgba(232, 3, 112, 0.2) 49.3%,
          rgba(93, 13, 86, 0.2) 94.64%
        );
        filter: blur(50px);
        border-radius: 138px;
        transform: rotate(-0.17deg);
      }
      .text-1 {
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        color: #ffffff;
        max-width: 431px;
      }
      .text-2 {
        font-weight: 700;
        font-size: 36px;
        line-height: 140%;
        color: #444bd3;
      }
    }
    &-cta {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      button:last-child {
        margin-left: 15px;
      }
    }
  }

  .newsletter {
    &-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: linear-gradient(
        150deg,
        #9d85f3 0%,
        #b0a3f6 25%,
        #cac3f7 50%,
        #d6c2f4 100%
      );
      background-size: cover;
      padding: 85px 0px;
      border-radius: 8px;
    }
    &-heading {
      font-weight: 800;
      font-size: 40px;
      line-height: 140%;
      background: linear-gradient(
        90deg,
        #454bd2 22.86%,
        #1d2059 61.82%,
        #000000 96.19%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      margin-bottom: 12px;
    }
    &-sub {
      font-weight: 400;
      font-size: 16px;
      line-height: 180%;
      text-align: center;
      color: rgba(18, 17, 39, 0.8);
      max-width: 482px;
      margin-bottom: 40px;
    }
    &-cta {
      display: flex;
      align-items: center;
      grid-gap: 20px;
      & > input {
        font-weight: 400;
        font-size: 14px;
        width: 265px;
        line-height: 180%;
        color: rgba(18, 17, 39, 0.4);
        outline: none;
        padding: 14px 16px;
        background: transparent;
        border: 1px solid rgba(18, 17, 39, 0.12);
        color: #000;
        border-radius: 8px;
      }
    }
  }
}

@include media("<=medium") {
  .home__box {
    & > .container {
      grid-gap: 20px;
      max-width: 90%;
      padding-bottom: $home--mobile__spacing;
    }

    .trial {
      &-box {
        flex-direction: column;
        grid-gap: 50px;
        padding: $home--mobile__spacing 0px;
      }
      &-content {
        flex: 1;
        .text-1 {
          font-size: 16px;
        }
        .text-2 {
          font-size: 25px;
        }
      }
      &-cta {
        flex-direction: column;
        align-self: stretch;
        grid-gap: 16px;
        button:last-child {
          margin-left: 0px;
        }
      }
    }

    .newsletter {
      &-box {
        flex-direction: column;
        padding: 85px 0px;
      }
      &-heading {
        font-size: 36px;
      }
      &-sub {
        font-size: 12px;
        max-width: 295px;
      }
      &-cta {
        flex-direction: column;
        align-items: stretch;
      }
    }
  }
}
