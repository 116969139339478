.alerts__container {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  max-width: 40rem;
  * {
    font-family: "Poppins";
  }
  .divider {
    height: 1px;
    width: 100%;
    border: 1px dashed #000;
    margin: 20px 0px;
  }
  .disclaimer {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 20px;
    color: #bfbfbf;
    mix-blend-mode: normal;
    img{
        margin-right: 10px;
    }
  }
  .cta {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    margin-top: 30px;
  }
  .disabled {
    label {
      color: #bfbfbf !important;
      span {
        color: #bfbfbf !important;
      }
    }
  }
  .heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
  }
  .subHeading {
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    mix-blend-mode: normal;
    opacity: 0.4;
  }
  .inputs {
    label {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
      span {
        margin-left: 3px;
        color: red;
      }
    }
    input {
      width: 100%;
      padding: 5px 12px;
      font-size: 12px;
      line-height: 22px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
    }
  }
}
