.open-engine-features {
  margin: auto;
  margin-top: 4rem;
  padding-top: 4rem;
  max-width: 80%;
  text-align: center;
  background-image: url("../../../images/features-bg-1.svg"),
    url("../../../images/features-bg-2.svg");
  background-repeat: no-repeat;
  background-position-y: 1%, top;
  background-position-x: center;
  background-size: 85%, contain;

  .header {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;

    .subtitle {
      text-align: center;
      line-height: unset;
      width: fit-content;
      font-size: 0.75rem;
      font-weight: 600;
      letter-spacing: 3.2px;
      border-radius: 12px;
      background: linear-gradient(91deg, #4154fe 0.46%, #7163f8 98.67%);
      margin: auto;
      padding: 0.3em 2em;
      text-transform: uppercase;
    }

    .title {
      font-size: 4rem;
      font-weight: 600;
      letter-spacing: -2px;
      margin: 0.25em auto;
      margin-top: 0.75em;

      span {
        font-weight: 300;
      }
    }

    .desc-1 {
      color: #c5c5c5;
      margin: 2.5em auto;
      font-weight: 300;
    }

    .desc-2 {
      color: white;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 1.5em;
    }
  }

  .feature-container {
    margin: 0 auto;
    margin-top: 4rem;
    margin-bottom: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    justify-items: center;
    align-items: stretch;
    gap: 2rem;

    .feature {
      background-color: #0e0e0e;
      border-radius: 2em;
      text-align: center;
      max-width: unset;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 0.5rem;
      padding: 2em;

      img {
        width: fit-content;
        margin: auto;
      }

      h3 {
        font-size: 1.25rem;
        margin: 0;
        margin-top: 0.75em;
      }

      p {
        font-size: 0.85rem;
        margin: 0;
      }

      a {
        display: inline-block;
        font-size: 0.85rem;
        margin: 0;
        color: #4154fe;
        font-weight: 500;
      }
    }
  }

  .mobile {
    display: none;
  }
}

@include media("<=xl") {
  .open-engine-features {
    max-width: 100%;
    padding-top: 3rem;
    padding-bottom: 2em;
    margin-top: 1.5rem;
    background-position-y: top;
    background-size: contain;

    .header {
      max-width: 95%;
      margin: auto;

      .introducing {
        font-size: 1.5rem;
        margin-top: 1.5em;
        margin-bottom: 1em;
      }

      .title {
        font-size: 3rem;
      }

      .desc-1 {
        font-size: 0.8rem;
        line-height: 1.5em;
      }

      .desc-2 {
        font-size: 1rem;
        max-width: 80%;
        margin: auto;
      }
    }

    .feature-container {
      margin-top: 4rem;
      margin-bottom: 6rem;
      grid-template-columns: 1fr 1fr;
      max-width: 90%;

      .feature {
        gap: 0.75rem;
        height: 100%;
        padding: 1em;

        img {
          width: 40%;
          margin: 0 auto;
        }

        p {
          max-width: 100%;
        }
      }
    }
  }
}

@include media("<=medium") {
  .open-engine-features {
    .header {
      .title {
        font-size: 2rem;
      }
    }

    .feature-container {
      .swiper-pagination {
        bottom: -4rem !important;
      }
    }

    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }

    .consultation-btn {
      margin-top: 3rem;
    }

    .swiper-container {
      overflow: unset !important;
    }
  }
}
