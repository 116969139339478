.case {
  *{
    font-family: "Poppins";
  }
  &__container {
    display: flex;
    align-items: center;
    background-image: url('../../../images/case_study.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 100%;
    height: 60vh;
    margin: 5rem 0rem;
    *{
      font-family: "Poppins";
    }
  }
  &__background {
    flex: 2;
    margin-left: auto;
    height: 35rem;
  }
  &__text {
    &-container {
      display: flex;
      flex: 3;
      flex-direction: column;
      // margin-left: 10rem;
    }
    &-tag {
      font-weight: 600;
      font-size: 14px;
      line-height: 170%;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      color: #6068db;
      margin-bottom: 12px;
    }
    &-heading {
      font-weight: 700;
      font-size: 40px;
      line-height: 140%;
      color: white;
    }
    &-sub {
      width: 35%;
      font-weight: 400;
      font-size: 16px;
      line-height: 180%;
      color: rgba(255, 255, 255, 0.56);
    }
  }
}

.case{
  display: flex;
  &__left{
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    .dropdown__box{
      width: 80%;
    }
  }
  &__right{
    display: flex;
    flex: 3;
    flex-direction: column;
    grid-gap: 24px;
  }
}

.case__card {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  background: rgba(255, 255, 255, 0.04);
  box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.08);
  border-radius: 12px;
  .case {
    &__text {
      padding: 24px;
    }
    &__img {
      object-fit: contain;
    }
    &__category {
      font-weight: 600;
      font-size: 14px;
      line-height: 170%;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      margin-bottom: 8px;
    }
    &__heading {
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      color: #ffffff;
      margin-bottom: 24px;
    }
    &__sub {
      font-weight: 400;
      font-size: 16px;
      line-height: 180%;
      color: rgba(255, 255, 255, 0.56);
      margin-bottom: 24px;
    }
    &__cta {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 180%;
      color: #599bf9;
      cursor: pointer;
      &-arrow {
        margin-left: 10px;
        transform: rotate(90deg);
        height: 14px;
      }
    }
  }
  .b2b {
    color: #a78bfa;
  }
  .healthtech {
    color: #ffac3d;
  }
  .edtech {
    color: #ffac3d;
  }
}
@include media("<=medium") {
  .case{
    flex-direction: column;
    &__left{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .case{
    &__container{
      background-size: auto;
      background-position: -180%;
    }
    &__text{
      &-container{
        margin: 0rem 1rem;
      }
      &-sub{
        width: 70%;
      }
    }
  }
}
