.price-dialog {
  display: flex;
  width: 100%;
  padding: 20px 0px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  .pricing-heading{
    color: #000;
    font-size: 24px;
    font-weight: 500;
  }
  .pricing-content-box {
    display: grid;
    width: 100%;
    max-width: 70%;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
  }
  .current__plan {
    background: #f2f3ff;
    color: #444bd3;
  }
  .pricing-card {
    border: 1px solid #000000;
    position: relative;
    border-radius: 12px;
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    .divider {
      height: 1px;
      background: rgba(255, 255, 255, 0.12);
      border: none;
      margin: 46px 0px 32px 0px;
    }
    .card {
      &-popular {
        position: absolute;
        right: 10px;
        top: 10px;
        display: none;
        align-items: center;
        justify-content: center;
        padding: 6px 16px;
        background: #4031b3;
        border-radius: 12px;
        font-weight: 700;
        font-size: 10px;
        line-height: 14px;
        text-transform: uppercase;
        letter-spacing: 0.833333px;
        color: #f496d1;
      }
      &-heading {
        font-weight: 700;
        font-size: 20px;
        line-height: 140%;
        color: #6068db;
        margin-bottom: 16px;
      }
      &-sub {
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
        color: #000;
        max-width: 232px;
        margin-bottom: 16px;
      }
      &-quote {
        color: #000;
        margin-bottom: 32px;
        display: flex;
        align-items: flex-end;
        grid-gap: 4px;
        span {
          display: block;
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 12px;
        }
      }
      &-tag {
        margin-bottom: 10px;
      }
      &-type {
        &:not(:last-child) {
          margin-bottom: 12px;
        }
        &-heading {
          font-weight: 600;
          font-size: 14px;
          line-height: 170%;
          letter-spacing: 0.8px;
          text-transform: uppercase;
          color: #3c3c3c;
          margin-bottom: 8px;
        }
        &-points {
          display: flex;
          flex-direction: column;
          grid-gap: 12px;
          div {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 180%;
            color: #000;
            img {
              margin-right: 13px;
            }
          }
        }
      }
    }
  }

  .popular {
    border: 1px solid #444bd3;
    .card-popular {
      display: flex;
    }
  }
}

@include media("<=medium") {
  .price-dialog {
    .pricing {
      & > .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 90%;
      }
      &-heading {
        margin-bottom: 16px;
      }
      &-subheading {
        margin-bottom: 50px;
      }
    }
    .pricing-card {
      .card {
        &-quote {
          span {
            margin-bottom: 7px;
          }
        }
      }
    }
  }
}
