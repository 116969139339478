.open-engine-videos {
  .videos-container {
    margin-top: 4rem;
    max-width: 50%;
    // margin: auto;
  }
}

.swiper-pagination {
  bottom: -2rem !important;
}

.swiper-container {
  overflow: unset !important;
}

.swiper-button-next,
.swiper-button-prev {
  height: auto !important;
  width: auto !important;
  &::after {
    font-size: 12px !important;
    font-weight: bold !important;
    background-color: white;
    border-radius: 50%;
    padding: 0.25em !important;
    height: 3.5em !important;
    width: 3.5em !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}

.swiper-button-next {
  right: -100px !important;
}

.swiper-button-prev {
  left: -100px !important;
}

@include media("<=medium") {
  .open-engine-videos {
    max-width: 90%;
    margin: auto;
    .videos-container {
      max-width: 100%;
      margin-top: 2rem;
    }
  }

  .swiper-button-next {
    right: -15px !important;
  }

  .swiper-button-prev {
    left: -15px !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    &::after {
      font-size: 10px !important;
      font-weight: bold !important;
      background-color: white;
      border-radius: 50%;
      padding: 0.25em !important;
      height: 2.5em !important;
      width: 2.5em !important;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }
  }
}
