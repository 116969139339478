.waitlist-btn-container {
  display: flex;
  justify-content: center;

  .waitlist-btn {
    transition: all 0.4s ease;
    display: block;
    background-color: white;
    padding: 0.5em 2em;
    color: black !important;
    border-radius: 0.5em;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-self: center;

    &:hover {
      background-color: #4154fe;
      color: white !important;
      text-decoration: none !important;
    }
  }
}
