.testimonial-box {
  width: 100%;
  .main-box {
    width: 100%;
    display: flex;
    grid-gap: 25px;
    overflow-x: scroll;
    touch-action: none;
    .item {
      position: relative;
      background: #0e1330;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 25px;
      padding: 25px;
      width: 350px;
      border: 1px solid #282d45;
      border-radius: 10px;
      .user-details {
        display: flex;
        grid-gap: 10px;
        img {
          object-fit: contain;
          max-width: 40px;
        }
        & > div {
          display: flex;
          grid-gap: 10px;
          flex-direction: column;
          .name {
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            color: #f6f6f7;
          }
          .designation {
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #8f9bb7;
          }
        }
      }
      .feedback {
        font-size: 16px;
        line-height: 24px;
        color: #f6f6f7;
      }
      .qoute {
        position: absolute;
        right: 25px;
        top: 25px;
      }
    }
    
  }
  .main-box::-webkit-scrollbar {
    display: none;
  }
  .testimonial-ctas {
    display: flex;
    grid-gap: 15px;
    margin-top: 45px;
    justify-content: center;
    button {
      cursor: pointer;
      background: #0e1330;
      outline: none;
      padding: 12px;
      border: 1px solid #282d45;
      border-radius: 8px;
    }
    button:first-of-type{
      svg{
        transform: rotate(180deg);
      }
    }
  }
}
