.forget-password {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: -5rem;
  .heading {
    font-weight: 700;
    font-size: 34px;
    line-height: 36px;
    letter-spacing: -0.06em;
    color: #1e1e1e;
    margin-bottom: 30px;
  }
}
