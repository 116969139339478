.home__box {
  background-color: #000;
  margin-top: 0px;
}

.web {
  display: block;
}

.mobile {
  display: none;
}

.text__tag {
  font-weight: 600;
  font-size: 14px;
  line-height: 170%;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: #6068db;
}
.text__main {
  font-weight: 700;
  font-size: 56px;
  line-height: 120%;
  text-align: center;
  color: #121127;
}

.text__heading {
  font-weight: 700;
  font-size: 40px;
  line-height: 140%;
  color: #ffffff;
}

.text__sub {
  font-weight: 400;
  font-size: 19px;
  line-height: 180%;
  color: rgba(255, 255, 255, 0.56);
}

.button__main {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  border-radius: 8px;
  img {
    margin-left: 13px;
  }
}

.button__primary {
  color: #fff;
  background: #444bd3;
  transition: 0.4s all;
  &:hover {
    background: #181da4;
  }
  &:disabled {
    background-color: #aab7c4;
  }
}

.button__secondary {
  border: 1px solid rgba(18, 17, 39, 0.12);
  color: #121127;
}

.button__tertiary {
  color: #fff;
  background: rgba(255, 255, 255, 0.08);
}

.button__login {
  color: black;
  transition: 0.4s all;
  padding: 6px 24px;
  border: 1px solid black;
  &:hover {
    background: #444bd3;
    color: white;
    border: 1px solid #444bd3;
  }
}

.button__login-secondary {
  color: white;
  border-radius: 0.5em;
  border: 1px solid white;
  &:hover {
    background: #444bd3;
    color: #fff;
  }
}

.button__signup,
.button__join-waitlist {
  background-color: #444bd3;
  color: white;
  transition: 0.4s all;
  padding: 6px 24px;
  // font-size: 14px;
  border: 1px solid #444bd3;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  &:hover {
    border: 1px solid #181da4;
    background: #181da4;
    color: #fff;
  }
}

.button__join-waitlist {
  padding: 0.5em 2em !important;
  font-family: "Poppins", sans-serif;
}

.button__signup-secondary {
  color: #fff;
  border: 1px solid #fff;
  &:hover {
    background: #181da4;
    color: #fff;
    border: 1px solid #000;
  }
}

.landing {
  &__box {
    height: auto;
    padding-bottom: 200px;
    padding-top: 8rem;
    background: -webkit-linear-gradient(
      334deg,
      rgb(208, 217, 246) 0%,
      rgb(236, 234, 235) 95%,
      rgb(239, 235, 234) 100%
    );
    background: -o-linear-gradient(
      334deg,
      rgb(208, 217, 246) 0%,
      rgb(236, 234, 235) 95%,
      rgb(239, 235, 234) 100%
    );
    background: -ms-linear-gradient(
      334deg,
      rgb(208, 217, 246) 0%,
      rgb(236, 234, 235) 95%,
      rgb(239, 235, 234) 100%
    );
    background: -moz-linear-gradient(
      334deg,
      rgb(208, 217, 246) 0%,
      rgb(236, 234, 235) 95%,
      rgb(239, 235, 234) 100%
    );
    background: linear-gradient(
      116deg,
      rgb(208, 217, 246) 0%,
      rgb(236, 234, 235) 95%,
      rgb(239, 235, 234) 100%
    );
    background-repeat: no-repeat;
    background-size: cover;

    div {
      font-family: "Poppins";
    }
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-gap: 10px;
    }
  }
  &__heading {
    span {
      color: #005fff;
    }
  }
  &__sub {
    text-align: center;
    color: rgba(18, 17, 39, 0.72);
    max-width: 660px;
    margin-bottom: 35px;
  }
  &__cta {
    display: flex;
    grid-gap: 30px;
    margin-bottom: 28px;
  }
  &__hosted {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #121127;
  }
  &__images {
    display: flex;
    align-items: center;
    grid-gap: 30px;
    img {
      max-height: 40px;
      object-fit: contain;
    }
  }
  &__tag {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    &-text {
      color: #121127;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      span{
        font-weight: 700;
      }
    }
  }
}

.g2 {
  display: flex;
  background-color: #ffffff85;
  box-shadow: 8.3871px 5.87097px 16.7742px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  height: 54px;
  overflow: hidden;
  &_box-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1px;
    padding: 0px 15px;
  }
  &_rating {
    font-weight: 800;
    font-size: 13px;
    line-height: 13px;
    color: #151719;
  }
  &_link {
    font-size: 10px;
    line-height: 7px;
    text-decoration-line: underline;
    cursor: pointer;
  }
  &_img-box {
    background-image: url("../../../images/g2_logo_back.svg");
    width: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-repeat: no-repeat;
    img {
      margin-left: 10px;
    }
  }
}

.video__box {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 25rem;
  position: relative;
  width: 100%;
  padding-bottom: 85px;

  .text__heading {
    z-index: 999;
    text-align: center;
  }
  .text__sub {
    z-index: 999;
  }
  .mask {
    position: absolute;
    clip-path: polygon(0 6%, 100% 0, 100% 100%, 0% 100%);
    background-color: #000;
    inset: 0;
    top: -6rem;
  }
}

@include media("<=medium") {
  .text__main {
    font-size: 29px;
    line-height: 140%;
  }
  .text__heading {
    font-size: 25px;
  }
  .text__sub {
    font-size: 14px;
    max-width: 300px;
  }
  .web {
    display: none;
  }
  .mobile {
    display: block;
  }
  .button__main {
    padding: 10px 20px;
    font-size: 15px;
    img {
      margin-left: 10px;
    }
  }

  .landing {
    &__box {
      padding-bottom: 100px;
    }
    &__cta {
      flex-direction: column;
      grid-gap: 16px;
      button {
        width: -webkit-fill-available;
      }
    }
    &__images {
      grid-gap: 20px;
      img {
        max-height: 27px;
        object-fit: contain;
      }
    }
  }
  .video__box {
    height: auto;
    min-height: 5rem;
    .mask {
      top: -3rem;
      clip-path: polygon(0 6%, 100% 0, 100% 100%, 0% 100%);
    }
  }

  .button__join-waitlist {
    padding: 0.5em 2em !important;
  }
}
