.link_aws_container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 40px 30px;
  border-radius: 8px;
  min-width: 40rem;
  * {
    font-family: "Poppins";
  }
  .link-copy {
    display: flex;
    cursor: pointer;
    align-items: center;
    align-self: center;
    font-size: 16px;
    img {
      margin-left: 15px;
    }
  }
  .error {
    color: red;
    max-width: 700px;
    font-size: 12px;
    word-wrap: break-word;
  }
  .heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    margin-bottom: 8px;
  }
  .sub-heading {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    mix-blend-mode: normal;
    opacity: 0.4;
    margin-bottom: 20px;
  }
  .button__primary {
    padding: 10px 20px;
    font-size: 14px;
  }
  .link-aws-steps {
    ul {
      display: flex;
      flex-direction: column;
      grid-gap: 20px;
      list-style-type: none;
      li {
        display: flex;
        align-items: flex-start;
        grid-gap: 20px;
      }
    }
    .image-stack {
      display: flex;
      font-size: 16px;
      flex-direction: column;
      grid-gap: 10px;
      align-items: flex-start;
      img {
        min-height: 4rem;
      }
    }
    .aws-verification {
      display: flex;
      align-items: center;
      font-size: 14px;
      img {
        max-width: 1.5rem;
        margin-right: 15px;
      }
    }
  }
}

@include media("<=medium") {
  .link_aws_container {
    padding: 25px 20px;
    min-width: 0px;
    .heading {
      font-weight: 600;
      font-size: 16px;
    }
    .sub-heading {
      font-size: 12px;
    }
    .link-copy {
      display: flex;
      cursor: pointer;
      align-items: center;
      align-self: center;
      font-size: 12px;
      img {
        margin-left: 15px;
        max-height: 12px;
      }
    }
    .error {
      font-size: 10px;
    }

    .button__primary {
      padding: 10px 20px;
      font-size: 12px;
    }
    .link-aws-steps {
      ul {
        grid-gap: 15px;
        padding: 0px;
        li {
          display: flex;
          flex-direction: column;
          grid-gap: 10px;
        }
      }
      .image-stack {
        font-size: 12px;
      }
      .aws-verification {
        font-size: 12px;
      }
    }
  }
}
