.open-engine {
  background-image: url("../../../images/intro-bg.svg");
  background-repeat: no-repeat;
  background-position-x: 100%;
  margin: 0;
  padding-top: 10rem;
  position: relative;

  & * {
    font-family: "Poppins", sans-serif;
  }
}

@include media("<=medium") {
  .open-engine {
    background-position-x: left;
    background-position-y: 2%;
    background-size: contain;
  }

  br {
    display: none;
  }
}

.swiper-slide {
  height: 100%;
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-slide {
  width: 100%;
}

.swiper-pagination-bullet {
  background: #d0d0d0 !important;
}

.swiper-pagination-bullet-active {
  background: #d6afaf !important;
}

.swiper {
  width: 600px;
  height: 100%;
}

.swiper-slide {
  height: auto !important;
  text-align: center;
}
.swiper-content {
  height: 100% !important;
}

.waitlist-btn-modal {
  position: relative;

  .close-btn {
    position: absolute;
    top: 10;
    right: 10;
    padding: 1rem;
    cursor: pointer;
  }

  #waitlist-form {
    width: 100% !important;
    height: 100% !important;
    border: none !important;
  }

  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 24;
    padding: 1em;
    outline: none;
    width: 400px;
    height: 600px;
    overflow: hidden;
  }
}
