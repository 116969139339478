.scroll-to-top {
  position: fixed;
  bottom: 1.25rem;
  right: 1.25rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444bd3;
  color: white;
  border-radius: 50%;
  padding: 0.6em;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 100000000;

  &:hover {
    background-color: #181da4;
  }
}

@include media("<=medium") {
  .scroll-to-top {
    bottom: 1rem;
    right: 1rem;
    width: 1.75rem;
    height: 1.75rem;
    font-size: 1rem;
    padding: 0.5em;
  }
}
