.open-engine-waitlist-container {
  text-align: center;
  border-radius: 3rem;
  background-color: #0e0e0e;
  padding: 5rem 2.5rem;
  max-width: 80%;
  margin: auto;
  margin-top: 8rem;
  background-image: url("../../../images/waitlist-bg.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  color: white;

  .desc {
    font-size: 1.5rem;
    line-height: 0.75em;
  }

  .heading {
    font-size: 3rem;
    font-weight: 600;
    margin: 2rem auto;
    line-height: 1em;

    span {
      font-weight: 300;
    }
  }

  .btn {
    display: inline-block;
    background-color: white;
    padding: 0.5em 1.5em;
    border-radius: 0.5em;
    font-weight: 500;
    margin: 1em 0;

    span {
      background: linear-gradient(97deg, #01031e 35.05%, #7163f8 130.8%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .footer {
    font-size: 0.75rem;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
  }
}

@include media("<=xl") {
  .open-engine-waitlist-container {
    padding: 4em 1em 15em 1em;
  }
}

@include media("<=medium") {
  .open-engine-waitlist-container {
    border-radius: 8px;
    padding: 4em 1em 10em 1em;
    max-width: 95%;
    margin-top: 4rem;
    background-size: 65%;

    .desc {
      font-size: 1rem;
    }

    .heading {
      font-weight: bold;
      font-size: 1.75rem;
    }

    .btn {
      margin: 1em 0;
      width: 90%;

      span {
        font-weight: 600;
      }
    }
  }
}
