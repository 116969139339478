.zip-ai {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: #fff;
  color: #000000;
  width: 44rem;
  margin-bottom: 50px;

  * {
    font-family: "Poppins";
  }
  &-read-more {
    color: blue !important;
    margin-left: 6px;
    &:hover {
      text-decoration: underline;
    }
  }

  &-details {
    display: flex;
    grid-gap: 50px;
    margin-top: 1rem;
    &-left {
      flex: 4;
    }
    &-right {
      flex: 3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .zipAI_video {
    &-iframe {
      position: fixed;
      inset: 0;
      z-index: 99999999;
      background-color: rgba(0 0 0 / 39%);
      display: flex;
      align-items: center;
      justify-content: center;
      .cross-button {
        position: absolute;
        top: 9%;
        right: 6%;
        width: 35px;
        cursor: pointer;
      }
      .iframe-container {
        position: relative;
        overflow: hidden;
        width: 80%;
        height: 80%;
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    &__image {
      position: relative;
      transition: 0.3s all;
      overflow: hidden;
      border-radius: 14px;
      width: 80%;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      z-index: 2;
      border-radius: 50%;
      cursor: url("../../../images/play.svg"), auto;
      .custom-cursor {
        position: absolute;
        pointer-events: none;
      }
      .play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover {
        filter: drop-shadow();
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
          rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
          rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        box-shadow: #9499ff 0px 0px 0px 3px;
      }
    }
  }

  .heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
  }
  .subHeading {
    font-weight: 400;
    font-size: 14px;
    margin-top: 6px;
    line-height: 20px;
    mix-blend-mode: normal;
    opacity: 0.4;
    margin-bottom: 10px;
  }
  .details-cta {
    margin-top: 2rem;
    display: flex;
    grid-gap: 20px;
  }
  .enable-box {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    grid-gap: 10px;
    .enable-heading {
      font-size: 17px;
      font-weight: 500;
    }
  }
  .list {
    font-size: 14px;
    width: 86%;
    line-height: 26px;
  }
}
