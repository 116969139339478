.open-engine-key-challenges {
  border-radius: 3rem;
  // background-color: #151515;
  background-color: #0e0e0e;
  color: #fff;
  padding: 4rem 2.5rem;
  max-width: 80%;
  margin: auto;
  margin-top: 6rem;
  background-image: url("../../../images/key-challanges-bg.svg");
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;

  .subtitle {
    text-align: center;
    line-height: unset;
    width: fit-content;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 3.2px;
    border-radius: 12px;
    background: linear-gradient(94deg, #ef4141 3.09%, #4154fe 147.5%);
    margin: auto;
    padding: 0.3em 2em;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  .title {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -2.5px;
    text-align: center;
    margin-top: 0.5em;
    word-spacing: 3px;
  }

  .challenges-container {
    display: grid;
    margin-top: 5em;
    align-items: stretch;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.5rem;
    justify-content: space-between;
  }

  .challenge-item {
    .challenge-icon {
      padding: 0.75em;
      background-color: #1b1b1b;
      border-radius: 0.75em;
      border: 1px solid #282828;
    }

    .challenge-tags {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      margin: 0.75em 0;
    }

    .challenge-tag {
      font-size: 0.6rem;
      padding: 0.2em 1em;
      border-radius: 0.75em;
      background: #131313;
      border: 1px solid #282828;
      box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.12);
      width: fit-content;
      font-family: Poppins;
    }

    .challenge-title {
      font-size: 1.05rem;
      margin: 0.75em 0 0.25em 0;
    }

    .challenge-description {
      margin: 0;
      font-size: 0.8rem;
      color: #c5c5c5;
      max-width: 85%;
    }
  }
}

@include media("<=xl") {
  .open-engine-key-challenges {
    max-width: 95%;
    background-position: top;
    text-align: center;
    margin: 3.5rem auto;

    .header {
      max-width: 100%;
    }

    .subtitle {
      white-space: initial;
      padding: 0.25em 1.5em;
      margin: auto;
    }

    .title {
      font-size: 1.5rem;
      margin-top: 1.5em;
    }

    .challenges-container {
      max-width: 100%;
      margin-top: 40px;
      grid-template-columns: 1fr 1fr;
      gap: 2em;

      .challenge-item {
        margin-top: 1.5em;

        .challenge-title {
          font-size: 1rem;
        }

        .challenge-icon {
          margin: auto;
        }

        .challenge-tags {
          justify-content: center;
        }

        .challenge-description {
          max-width: 100%;
        }
      }
    }
  }
}

@include media("<=medium") {
  .open-engine-key-challenges {
    .challenges-container {
      grid-template-columns: 1fr;
      .challenge-item {
        .challenge-tags {
          .challenge-tag {
            font-size: 12px;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
