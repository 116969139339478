.partners {
  &__box {
    display: flex;
    background-color: #000;
    padding-bottom: $home--dekstop__spacing;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-gap: 85px;
      max-width: none;
    }
    .marquee {
      img {
        margin: 0px 40px;
        min-height: 40px;
        max-width: 200px;
        object-fit: contain;
      }
      .kenko-fit {
        padding: 6px;
      }
    }
  }
  &__heading {
    text-align: center;
  }

  &__cta {
    display: flex;
    grid-gap: 45px;
    button {
      display: flex;
      color: #fff;
      img {
        margin-left: 13px;
      }
    }
  }
}

@include media("<=medium") {
  .partners {
    &__box {
      padding-bottom: $home--mobile__spacing;
      .marquee {
        img {
          margin: 0px 20px;
          max-width: 9rem;
        }
      }
    }
    &__cta {
      flex-direction: column;
      grid-gap: 15px;
    }
  }
}
