.home__use {
  & > div {
    display: flex;
    flex-direction: column;
    grid-gap: 70px;
    align-items: center;
    padding: $home--dekstop__spacing 0px;
    max-width: 80%;
  }
  * {
    font-family: "Poppins";
  }
}

.htiles__container {
  display: flex;
  grid-gap: 20px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .tile {
    cursor: pointer;
    padding: 10px 22px;
    border: 1px solid #ffffff;
    border-radius: 50px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #ffffff;
    transition: 0.2s all;
    flex-shrink: 0;
    &:hover {
      background: #444bd3;
      border: 1px solid transparent;
    }
  }
  .selected {
    background: #444bd3;
    border: 1px solid transparent;
  }
}

.bottom {
  display: flex;
  align-self: stretch;
  .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    grid-gap: 14px;
    align-items: center;
    padding: 0px 20px;
    .subtype {
      &-heading {
        font-weight: 500;
        font-size: 25px;
        line-height: 129.52%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #ffffff;
      }
      &-box {
        display: flex;
        overflow: auto;
        flex-direction: column;
        grid-gap: 60px;
        padding: 50px 30px;
        background: linear-gradient(
          129.91deg,
          rgba(66, 65, 97, 0.5) 0%,
          rgba(22, 24, 44, 0.5) 76.04%
        );
        border-radius: 30px;
        &::-webkit-scrollbar {
          display: none;
        }
        .type__tile {
          flex-shrink: 0;
          font-weight: 400;
          cursor: pointer;
          width: fit-content;
          font-size: 16px;
          line-height: 129.52%;
          letter-spacing: 0.02em;
          color: #ffffff;
          border-bottom: 1px solid transparent;
          transition: 0.4s all;
          &:hover {
            // border-bottom: 1px solid #fff;
            color: #444bd3;
          }
        }
        .selected{
            // border-bottom: 1px solid #fff;
            color: #444bd3;
        }
      }
    }
  }
  .right {
    flex: 4;
    display: flex;
    padding: 0px 40px;
  }
}

.use__content {
  &-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 2;
    grid-gap: 24px;
    .point__box {
      display: flex;
      flex-direction: column;
      grid-gap: 10px;
      .point {
        display: flex;
        align-items: center;
        img {
          margin-right: 13px;
        }
      }
    }
    & > div {
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.02em;
      color: #ffffff;
    }
    .heading {
      font-weight: 500;
      font-size: 24px;
    }
    .subHeading {
      max-width: 485px;
    }
  }
  &-right {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    img {
      max-height: 15rem;
      object-fit: contain;
    }
  }
}

@include media("<=medium") {
  .home__use {
    & > div {
      grid-gap: 30px;
      padding: $home--mobile__spacing 0px;
      max-width: 90%;
    }
  }

  .htiles__container {
    align-self: stretch;
    .tile {
      padding: 10px 22px;
    }
  }

  .bottom {
    align-self: stretch;
    flex-direction: column;
    .left {
      flex-direction: column;
      grid-gap: 14px;
      padding: 0px;
      .subtype {
        &-heading {
          font-size: 18px;
        }
        &-box {
          flex-direction: row;
          align-self: stretch;
          grid-gap: 30px;
          padding: 40px 10px;
        }
      }
    }
    .right {
      flex-direction: column;
      grid-gap: 20px;
      padding: 40px 5px;
    }
  }

  .use__content {
    &-left {
      flex: 1;
      align-items: flex-start;
      grid-gap: 24px;
      & > div {
        font-size: 12px;
        line-height: 18px;
        img {
          max-height: 18px;
        }
      }
      .heading {
        font-size: 18px;
      }
    }
  }
}
