.credit-usage {
  align-self: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  .hidden {
    height: 0px;
    width: 0px;
    visibility: hidden;
  }
}

.loading-box {
  img {
    max-width: 4rem;
  }
}

.error-card {
  align-self: flex-start;
  display: flex;
  align-items: center;
  grid-gap: 20px;
  color: red;
  button {
    padding: 5px 20px;
  }
}
