.benifit__box {
  padding: $home--dekstop__spacing 0px;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .benifit {
    &__heading {
      margin: 0px 0px 16px 0px;
    }
    &__sub {
      max-width: 649px;
      text-align: center;
      margin-bottom: 50px;
    }
  }
}
.benifit__tile {
  &-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 30px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 60px;
  &-heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #ffffff;
    margin: 24px 0px 16px 0px;
  }
  &-sub {
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    text-align: center;
    max-width: 360px;
    color: rgba(255, 255, 255, 0.56);
  }
}



@include media("<=medium"){
  .benifit__box {
    padding: $home--mobile__spacing 0px;
    .benifit {
      &__heading {
        margin: 0px 0px 8px 0px;
      }
    }
  }
  .benifit__tile {
    &-box {
      grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 1fr);
      grid-row-gap: 20px;
    }
    padding: 20px 60px;
    &-heading {
      font-size: 14px;
    }
    &-sub {
      font-size: 12px;
    }
  }
  

}