.loader{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999999;
    inset: 0;
    background-color: #000;
    img{
        max-height: 4rem;
        object-fit: contain;
    }

}

.white{
    background-color: #fff;
}
