.header-strip {
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.5px;
  background: #4153fe;
  background: linear-gradient(
    9deg,
    rgba(65, 83, 254, 1) 32%,
    rgba(239, 65, 65, 1) 83%
  );
  color: white;
  font-size: 0.8rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
  padding: 0.5em 0;
  text-align: center;

  .link-btn {
    color: white;
    font-weight: 600;
    cursor: pointer;
    border-bottom: 1px solid white;
    transition: all 0.3s ease;

    &:hover {
      text-decoration: none;
    }
  }
}

@include media("<=medium") {
  .header-strip {
    font-size: 0.65rem;
    padding: 0.3em 0;
  }
}
