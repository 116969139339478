.open-engine-beta-users {
  overflow: hidden;
  margin-top: 6em;

  .heading {
    color: #fff;
    text-align: center;
    align-self: center;
    font-size: 1.25rem;
    padding: 0;
    margin: 0;

    span {
      font-weight: 500;
    }
  }

  .content {
    display: flex;
    margin-top: 1.5rem;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }

  .logo-images {
    margin-top: 2em;
  }

  .logo-image {
    object-fit: contain;
    object-position: center;
    width: auto;
    max-width: 10rem;
    height: 2rem;
    align-self: stretch;
    margin-right: auto;
  }
}

@include media("<=xl") {
  .open-engine-beta-users {
    .logo-image {
      margin-right: 4rem;
    }
  }
}

@include media("<=medium") {
  .open-engine-beta-users {
    margin: auto;
    margin-top: 3em;
    max-width: 90%;

    .heading {
      max-width: 100%;
      font-size: 0.9rem;
      line-height: 1.5em;
    }

    .content {
      max-width: 100%;
    }

    .logo-images {
      max-width: 100%;
      gap: 2rem;
    }

    .logo-image {
      max-width: 7.5rem;
      height: 1.5rem;
    }
  }
}
