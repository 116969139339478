.open-engine-testimonials {
  max-width: 80%;
  margin: auto;
  margin-top: 6rem;

  .desktop {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2em;
  }

  .mobile {
    display: none;
  }

  .testimonials-columns {
    box-sizing: content-box;
    margin-top: 4rem;
    max-width: 1342px;
    margin: 0px auto;
    padding: 0.25em;
    padding-top: 3em;
    overflow: hidden auto;

    .testimonial {
      break-inside: avoid;
      border-radius: 1.25em;
      background: #1c1c1c;
      padding: 1.5em;
      box-shadow: #4154fe -1px -1px 3.5px;
      text-align: left;
      margin: unset;
      margin-bottom: 20px;

      .content {
        font-size: 0.85rem;
        color: #d0d0d0;
      }

      .testimonial-footer {
        display: flex;
        align-items: center;
        gap: 0.75em;
        margin-right: auto;
        margin-top: 1em;

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }

        .name {
          margin: 0;
          font-size: 0.9rem;
          line-height: 1em;
          font-weight: 500;
        }

        .designation {
          margin: 0;
          font-size: 0.7rem;
          color: #4154fe;
        }
      }
    }

    .testimonials-column {
      display: flex;
      flex-direction: column;
      gap: 1.5em;

      &:nth-child(odd) {
        justify-content: center;
      }
    }
  }
}

@include media("<=xl") {
  .open-engine-testimonials {
    max-width: 90%;
    margin-top: 4rem;

    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }

    .testimonials-columns {
      overflow: unset;
      margin-top: 2rem;

      .testimonial {
        max-width: 100%;
        height: 100%;
        justify-content: center;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
      }

      .swiper-pagination {
        bottom: -2rem !important;
      }
    }
  }
}
