.video {
  position: relative;
  overflow: hidden;

  z-index: 2;
  margin: auto;
  padding-top: 0;
  width: 100%;
  height: 100%;

  .video-box {
    border-radius: 14px;
    transition: 0.3s all ease;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    .content {
      color: white;
      text-align: center;
      position: absolute;
      z-index: 10;
      top: 25%;
      right: 50%;
      transform: translate(50%, -50%);

      .title {
        font-size: 2.25rem;
        font-weight: 600;
        line-height: 1.25em;
        letter-spacing: -2.5px;

        span {
          font-weight: 400;
        }
      }
    }

    .preview-img {
      transition: 0.3s all;
      overflow: hidden;
      border-radius: 14px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      z-index: 2;
      height: 100%;
      width: 100%;
    }

    .custom-cursor {
      position: absolute;
      pointer-events: none;
    }

    .video-bg {
      position: absolute;
      object-fit: cover;
      background-color: #4154fe;
      height: 100%;
      width: 100%;
      border-radius: 56px;
    }

    .play-btn {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 10;

      background: transparent;
      border-radius: 50%;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      transform: scale(1);
    }

    @keyframes pulse {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
      }

      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      }
    }
  }

  &-iframe {
    position: fixed;
    inset: 0;
    z-index: 99999999;
    background-color: rgba(0 0 0 / 39%);
    display: flex;
    align-items: center;
    justify-content: center;

    .cross-button {
      position: absolute;
      top: 9%;
      right: 6%;
      width: 35px;
      cursor: pointer;
    }

    .iframe-container {
      position: relative;
      overflow: hidden;
      width: 80%;
      height: 80%;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.open-engine-video-inner {
  height: 450px;
  margin: auto;
  border-radius: 2em;
  position: relative;
  font-family: "Poppins";
  overflow: hidden;
  transition: transform 0.4s, box-shadow 0.2s;
}

.open-engine-video-outer {
  max-width: 100%;
}

.landing-page-video-outer {
  max-width: 75%;
  transform: translateY(-175px);
}

@include media("<=xl") {
  .video {
    &-iframe {
      .cross-button {
        top: 5%;
        right: 4%;
      }

      .iframe-container {
        width: 100%;
        height: 35%;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    &__image {
      max-width: 80%;
      transform: translateY(-80px);
      .play-button {
        width: 40px;
      }
    }

    .video-box {
      .content {
        .title {
          font-size: 2.5rem;

          br {
            display: inline;
          }
        }
      }

      .video-bg {
        border-radius: 40px;
      }

      .play-btn {
        width: 80px;
      }
    }
  }

  .open-engine-video-inner {
    max-width: 75%;
    height: 400px;
  }
}

@include media("<=xl") {
  .video {
    .video-box {
      .content {
        .title {
          font-size: 1.5rem !important;
        }
      }

      .play-btn {
        width: 75px;
      }
    }
  }
}

@include media("<=medium") {
  .video {
    .video-box {
      .play-btn {
        width: 50px;
      }
    }
  }

  .open-engine-video-inner {
    max-width: 90%;
    height: 230px;
  }

  .landing-page-video-outer {
    max-width: 90%;
    transform: translateY(-80px);
  }
}
