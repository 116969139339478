.pricing {
  padding: 40px 0px;
  & > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80%;
    transition: all;
    transition-duration: 100;
  }
  * {
    font-family: "Poppins";
  }
  &-heading {
    margin-bottom: 16px;
  }
  &-subheading {
    margin-bottom: 50px;
    font-size: 16px;
    font-style: italic;
  }
  &_value {
    background: #1c1b28;
    box-shadow: 0px 16.112px 20.14px -4.02799px rgba(0, 0, 0, 0.1),
      0px 8.05599px 8.05599px -4.02799px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 25px;
    padding: 60px 80px;
    color: #fff;
    margin-bottom: 80px;

    .first_heading {
      text-align: center;
      font-weight: 800;
      font-size: 48.3359px;
      line-height: 100%;
    }
    .second_heading {
      font-size: 14.5008px;
      line-height: 23px;
    }
  }
}

.pricing {
  &-content {
    display: flex;
    flex-direction: column;
  }

  &-val {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    color: #fff;
    & > span {
      font-weight: 700;
      font-size: 35px;
      line-height: 31px;
    }
    .price {
      display: flex;
      margin: auto;
      align-items: flex-end;
      &_value {
        font-weight: 700;
        font-size: 48px;
        line-height: 34px;
      }
      &_duration {
        font-weight: 500;
        font-size: 25px;
        line-height: 27px;
      }
    }
  }

  &-sliders {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    .slider {
      display: flex;
      grid-gap: 35px;
      &_trigger {
        width: 550px;
      }
      &_value {
        font-weight: 700;
        font-size: 17px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 2px;
        color: #ffffff;
      }
      &_label {
        font-size: 16px;
      }
    }
  }
}

.custom-plan {
  background: #1c1b28;
  box-shadow: 0px 16.112px 20.14px -4.02799px rgba(0, 0, 0, 0.1),
    0px 8.05599px 8.05599px -4.02799px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  display: flex;
  align-items: center;
  grid-gap: 60px;
  padding: 40px 60px;
  margin: 105px 0px 30px 0px;
  .plan-content {
    display: flex;
    flex-direction: column;
    grid-gap: 6px;
    & > :nth-child(1) {
      font-weight: 700;
      font-size: 32px;
      line-height: 140%;
      color: #ffffff;
    }
    & > :nth-child(2) {
      font-weight: 700;
      font-size: 15px;
      line-height: 140%;
      color: #6068db;
    }
  }
}

.popular {
  border: 1px solid #444bd3;
  .card-popular {
    display: flex;
  }
}

.faq-container {
  padding: 85px 0px;
  max-width: 75%;
}

.pricing-top-heading {
  font-size: 0.8rem;
  font-weight: 400;
  color: white;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.pricing-main-heading {
  font-size: 2.5rem;
  font-weight: 700;
  color: white;
  margin-bottom: 20px;
  max-width: 800px;
  line-height: 1.5em;
  text-align: center;

  .customized-pricing {
    background: linear-gradient(90deg, #9673f8 0%, #d05ced 85%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }
}

.talk-to-sales {
  color: black;
  margin-top: 15px;
  border: none;
  background-color: rgb(255, 255, 255);
  padding: 0.75rem 2.5rem;
  border-radius: 0.3em;
  font-weight: 500;
  font-size: 0.8rem;
  transition: 0.4s all;
  &:hover {
    background-color: #444bd3;
    color: white;
    cursor: pointer;
  }
}

.start-trial {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
  margin: 4rem 0 5rem 0;
  border: #444bd3 1px solid;
  border-radius: 0.8em;
  padding: 0.75rem;
  background-color: #141e33;
  transition: 0.4s all;

  &:hover {
    border: #3239c0 1px solid;
    background-color: #091736;
  }

  .start-trial-left {
    display: flex;
    align-items: center;
    gap: 0.6rem;

    .flash-icon {
      color: #0d77f3;
      font-size: 1.5rem;
    }

    .headline {
      font-size: 1rem;
      font-weight: 600;
      color: white;
      margin-right: 20px;
      padding: 0;
      margin: 0;
    }

    .sub-headline {
      font-size: 0.75rem;
      font-weight: 500;
      padding: 0;
      margin: 0;
      color: #b0bacf;
    }
  }

  .start-trial-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    font-weight: 500;
    cursor: pointer;

    .chevron-icon {
      color: white;
      font-size: 1rem;
    }
  }
}

@include media("<=medium") {
  .pricing {
    & > .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 90%;
    }
    &-heading {
      margin-bottom: 16px;
    }
    &-subheading {
      margin-bottom: 30px;
      font-size: 12px;
    }
    &_value {
      grid-gap: 15px;
      padding: 40px;

      .first_heading {
        font-size: 28px;
      }
      .second_heading {
        font-size: 9px;
        line-height: 14px;
      }
    }
  }
  .faq-container {
    padding: 30px 0px;
    max-width: none;
  }
  .custom-plan {
    grid-gap: 30px;
    padding: 30px 20px;
    margin: 60px 0px 30px 0px;
    flex-direction: column;
    .plan-content {
      display: flex;
      flex-direction: column;
      grid-gap: 8px;
      text-align: center;
    }
  }
  .pricing {
    &-val {
      flex-direction: column;
      grid-gap: 30px;
      & > span {
        font-size: 30px;
      }
      .price {
        &_value {
          font-size: 35px;
        }
        &_duration {
          font-size: 20px;
        }
      }
    }

    &-sliders {
      grid-gap: 20px;
      .slider {
        position: relative;
        &_trigger {
          width: 100%;
        }
        &_value {
          position: absolute;
          font-size: 12px;
          line-height: 20px;
          top: -25%;
        }
        &_label {
          font-size: 13px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .pricing-top-heading {
    font-size: 0.8rem;
  }

  .pricing-main-heading {
    font-size: 1.5rem;
  }

  .talk-to-sales {
    padding: 0.7rem 2rem;
    width: 100%;
    font-size: 0.8rem;
    color: black;
  }

  .start-trial {
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    width: unset;
    margin: 2.5rem 0 3.5rem 0;

    .start-trial-left {
      flex-direction: column;
      align-items: start;
      width: 100%;
      gap: 1rem;

      .headline {
        font-size: 0.9rem;
      }

      .sub-headline {
        font-size: 0.7rem;
      }
    }

    .start-trial-btn {
      font-size: 0.9rem;

      .chevron-icon {
        color: white;
        font-size: 1rem;
      }
    }
  }
}
