.deployment__box {
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  width: fit-content;
  height: 500px;
  background-color: #fff;
  padding: 30px;
  aspect-ratio: 1/1;
  * {
    font-family: "Poppins";
  }
  & > .heading {
    font-weight: 500;
    font-size: 22px;
    display: flex;
    align-items: center;
    line-height: 33px;
    margin-bottom: 6px;
    img {
      margin-left: 13px;
    }
  }
  & > .subHeading {
    max-width: 380px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 50px;
  }
}

.deployment__done {
  margin-top: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > .heading {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 60px;
    margin-top: 40px;
  }
  .deployment__cta{
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }
}
