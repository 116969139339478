.olake {
  &-header {
    display: flex;
    grid-gap: 40px;
    button {
      cursor: pointer;
    }
  }
  &-button {
    all: unset;

    color: #8d8d8d;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 160% */
  }
  &-joinlist-button {
    all: unset;
    border-radius: 4px;
    display: flex;
    padding: 4px 20px;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: #111;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 160% */
  }

  &-home {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 8rem;
      padding: 0px 24px;
      *{
        font-family: Poppins !important;
      }
    }
    &-heading {
      display: flex;
      flex-direction: column;
      grid-gap: 8px;
      text-align: center;
      font-size: 64px;
      font-style: normal;
      font-weight: 700;
      line-height: 77.04px; /* 120.375% */
      letter-spacing: -1.8px;
      &-1 {
        background: linear-gradient(135deg, #fff 0%, #71717a 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &-2 {
        background: linear-gradient(202deg, #fff -15.52%, #c7c7c7 71.31%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &-subheading {
      max-width: 850px;
      margin-top: 40px;
      color: #fff;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 133.333% */
    }
    &-img {
      margin-top: 55px;
    }
  }

  &-usecase {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    &-heading {
      color: #fff;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &-subheading {
      margin-top: 14px;
      color: #fff;
      text-align: center;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: 56px; /* 116.667% */
      letter-spacing: -0.48px;
    }
    &-list {
      display: flex;
      align-items: center;
      grid-gap: 52px;
      margin-top: 48px;
      div {
        border-radius: 32px;
        background: #202020;
        display: flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: #fff;
        text-align: center;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 100% */
      }
    }
  }
  &-banner {
    margin-top: 100px;
    width: 80%;
    border-radius: 8px;
    background: #202020;
    display: flex;
    flex-direction: column;
    background-image: url("../../../images/olake-banner-vector.svg");
    background-repeat: no-repeat;
    background-position: bottom left;
    padding-bottom: 350px;
    &-launch {
      color: #fff;
      margin: auto;
      font-size: 22.5px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 60px;
    }
    &-heading {
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &-1 {
        color: #193ae6;
        font-size: 108.952px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      &-2 {
        align-self: flex-end;
        color: #c4c4c4;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: -12px;
      }
    }
    &-subheading {
      text-align: center;
      margin: auto;
      margin-top: 30px;

      line-height: normal;
      line-height: normal;
      font-style: normal;
      font-size: 36px;

      &-type3 {
        background: linear-gradient(90deg, #193ae6 6.96%, #a3b1f5 21.56%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &-type1 {
        color: #fff;
        font-weight: 700;
      }
      &-type2 {
        color: #fff;
        font-weight: 400;
      }
    }
    &-cta {
      display: flex;
      align-items: center;
      margin: auto;
      grid-gap: 24px;
      margin-top: 40px;

      &-1 {
        all: unset;
        cursor: pointer;
        border-radius: 4px;
        background: #193ae6;
        display: flex;
        padding: 4px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 160% */
      }
      &-2 {
        all: unset;
        cursor: pointer;
        color: #8d8d8d;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 160% */
      }
    }
  }
}

@include media("<=medium") {
  .olake {
    &-header {
      grid-gap: 12px;
    }
    &-button {
      font-size: 16px;
    }
    &-joinlist-button {
      border-radius: 4px;
      padding: 4px 12px;
      font-size: 14px;
    }

    &-home {
      &-container {
        margin-top: 7rem;
        padding: 0px 20px;
      }
      &-heading {
        grid-gap: 4px;
        font-size: 32px;
        line-height: 40px; /* 120.375% */
        letter-spacing: 0px;
      }
      &-subheading {
        margin-top: 24px;
        font-size: 14px;
        line-height: 24px; /* 133.333% */
      }
      &-img {
        margin-top: 24px;
      }
    }

    &-usecase {
      margin-top: 50px;
      &-heading {
        font-size: 16px;
      }
      &-subheading {
        margin-top: 12px;
        font-size: 22px;
        line-height: 32px; /* 116.667% */
      }
      &-list {
        grid-gap: 20px;
        margin-top: 24px;
        div {
          padding: 8px 16px;
          font-size: 12px;
          line-height: 20px; /* 100% */
        }
      }
    }
    &-banner {
      margin-top: 40px;
      width: 90%;
      padding-bottom: 30px;
      background-image: none;
      &-launch {
        font-size: 16px;
        margin-top: 30px;
      }
      &-heading {
        &-1 {
          font-size: 60px;
        }
        &-2 {
          font-size: 12px;
          margin-top: -4px;
        }
      }
      &-subheading {
        margin-top: 24px;
        font-size: 18px;
      }
      &-cta {
        grid-gap: 24px;
        margin-top: 24px;

        &-1 {
          padding: 4px 12px;
          font-size: 14px;
          line-height: 26px; /* 160% */
        }
        &-2 {
          font-size: 16px;
          line-height: 26px; /* 160% */
        }
      }
    }
  }
}
