.use {
  *{
    font-family: "Poppins";
  }
  &__container {
    display: flex;
    align-items: flex-start;
    grid-gap: 4rem;
    margin-top: 8rem;
    margin-bottom: 70px;
    
  }
  &__text {
    &-container {
      display: flex;
      flex-direction: column;
      grid-gap: 12px;
    }
    &-tag {
      font-weight: 600;
      font-size: 14px;
      line-height: 170%;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      color: #6068db;
    }
    &-heading {
      font-weight: 700;
      font-size: 55px;
      line-height: 140%;
      color: #ffffff;
    }
    &-sub {
      font-weight: 400;
      font-size: 20px;
      line-height: 180%;
      color: rgba(255, 255, 255, 0.56);
    }
  }
  &__cases {
    &-box {
      display: flex;
      flex-direction: column;
      grid-gap: 120px;
      margin: 70px 0px;
    }
  }
}

.use__testimonial {
  &-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: rgba(255, 255, 255, 0.04);
    box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.08);
    border-radius: 12px;
    max-width: 90%;
    padding: 32px;
    grid-gap: 32px;
    margin: 70px auto;
  }
  &-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 180%;
    color: #ffffff;
  }
  &-author {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    .author {
      &-name {
        font-weight: 600;
        font-size: 14px;
        line-height: 180%;
        text-align: center;
        color: #ffffff;
      }
      &-position {
        color: rgba(255, 255, 255, 0.56);
      }
    }
  }
}

.divider {
  width: 100%;
  border: 2px solid #495057;
}

.use__card {
  display: flex;
  align-items: flex-start;
  grid-gap: 4rem;
  &-background{
    max-height: 250px;
    object-fit: contain;
  }
  &-container {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }
  &-order {
    flex-direction: row-reverse;
  }
  &-tag {
    font-weight: 600;
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: #6068db;
  }
  &-heading {
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    color: #ffffff;
  }
  &-sub {
    font-weight: 400;
    font-size: 18px;
    line-height: 180%;
    color: rgba(255, 255, 255, 0.72);
  }
}

.datazip__case {
  display: flex;
  align-items: center;
  padding: 32px 24px;
  width: 100%;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  .heading {
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    text-align: center;
    color: #ffffff;
  }
  .subHeading {
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    text-align: center;
    color: rgba(255, 255, 255, 0.56);
  }
  .box{
    display: flex;
  }
}

.integration__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0px;
  &-connector {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    margin-bottom: 60px;
    justify-content: center;
  }
  &-heading {
    align-self: center;
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    text-align: center;
    color: #ffffff;
    margin-bottom: 50px;
  }
  &-button {
    all: unset;
    padding: 14px 20px;
    border: 1px solid #495057;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
  }
}

.use__freeTrial {
  display: flex;
  align-items: center;
  margin: 100px 0px;
  .freeTrial {
    &__textbox {
      display: flex;
      flex-direction: column;
      position: relative;
      grid-gap: 4px;
      flex: 3;
      &-1 {
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: #ffffff;
      }
      &-2 {
        font-weight: 700;
        font-size: 36px;
        line-height: 140%;
        color: #444bd3;
      }
    }
    &__elipse {
      position: absolute;
      width: 455.05px;
      height: 163.84px;
      top: 0px;
      background: linear-gradient(
        90deg,
        rgba(253, 101, 66, 0.2) 8.19%,
        rgba(232, 3, 112, 0.2) 49.3%,
        rgba(93, 13, 86, 0.2) 94.64%
      );
      filter: blur(50px);
      border-radius: 138px;
      transform: rotate(-0.17deg);
    }
    &__cta {
      display: flex;
      flex: 2;
      justify-content: flex-end;
      &-common {
        all: unset;
        cursor: pointer;
        padding: 14px 32px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        border-radius: 8px;
      }
      &-1 {
        background: #444bd3;
        margin-right: 10px;
      }
      &-2 {
        background: rgba(255, 255, 255, 0.04);
      }
    }
  }
}

@include media("<=medium") {
  .use {
    &__container {
      flex-direction: column-reverse;
      grid-gap: 3rem;
    }
    &__text {
      &-heading {
        font-size: 24px;
      }
      &-sub {
        font-size: 16px;
      }
    }
  }
  .use__testimonial {
    &-text {
      font-size: 16px;
    }
  }
  .use__card {
    flex-direction: column;
    align-items: center;
    &-background{
      max-height: 200px;
      object-fit: contain;
    }
    &-order {
      flex-direction: column;
    }
    &-heading {
      font-size: 24px;
    }
    &-sub {
      font-size: 16px;
    }
  }
  .integration__box {
    &-heading {
      font-size: 24px;
    }
  }
  .use__freeTrial {
    flex-direction: column;
    grid-gap: 2rem;
    .freeTrial {
      &__textbox {
        &-1 {
          font-size: 24px;
        }
        &-2 {
          font-size: 24px;
        }
      }
      &__cta {
        &-common {
          padding: 10px 20px;
          font-size: 15px;
        }
      }
    }
  }
  .datazip__case{
    .heading{
      font-size: 24px;
      margin-bottom: 10px;
    }
    .box{
      flex-wrap: wrap;
    }
  }
}
