.home__connector {
  padding: $home--dekstop__spacing 0px;
  & > div {
    display: flex;
    max-width: 80%;
  }
  .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .Hconnector {
      &__heading {
        max-width: 456px;
        margin-bottom: 32px;
      }
      &__sub {
        max-width: 430px;
        margin-bottom: 32px;
      }
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 2rem;
    .connector-tiles {
      display: flex;
      align-self: stretch;
      flex-wrap: wrap;
      grid-row-gap: 20px;
      justify-content: space-around;
      .tile {
        cursor: pointer;
        padding: 11px 21px;
        background: rgba(255, 255, 255, 0.04);
        border: 1.075px solid #ffffff;
        border-radius: 107.5px;
        font-weight: 600;
        font-size: 12.9px;
        line-height: 180%;
        color: rgba(255, 255, 255, 0.8);
        transition: 0.4s all;
        &:hover {
          background: #555555;
          background: linear-gradient(180deg, #444bd3 0%, #444bd3 100%);
          border: 1.075px solid transparent;
        }
      }
      .selected {
        transition: 0.4s all;
        background: linear-gradient(180deg, #444bd3 0%, #444bd3 100%);
        border: 1.075px solid transparent;
      }
    }

    .connector-images {
      display: grid;
      width: 90%;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-row-gap: 1.5rem;
      grid-column-gap: 2rem;
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          object-fit: contain;
          max-height: 78px;
        }
      }
    }
  }
}

@include media("<=medium") {
  .home__connector {
    padding: $home--mobile__spacing 0px;
    & > div {
      flex-direction: column-reverse;
      grid-gap: 45px;
      max-width: 90%;
    }

    .left {
      .Hconnector {
        &__heading {
          margin-bottom: 20px;
        }
        &__sub {
          margin-bottom: 20px;
        }
      }
    }
  }
}
