.signup-box {
  display: flex;
  flex: 1 0 auto;
  background-color: #ffffff;
  min-height: 100vh;
  .signup-img {
    flex: 7;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../../images/signup-background.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    .signup-static {
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-gap: 1.5rem;
      width: 60%;
      .image {
        height: 4rem;
      }
      .heading {
        font-weight: 700;
        font-size: 46.7489px;
        line-height: 120%;
        text-align: center;
        color: #ffffff;
      }
      .subHeading {
        font-weight: 400;
        font-size: 16.696px;
        line-height: 180%;
        text-align: center;
        color: #ffffff;
      }
    }
  }
  .signup-cta {
    flex: 5;
    display: flex;
    padding-top: 5rem;
  }
  .signup-video {
    iframe {
      width: 400px;
      border-radius: 10px;
      min-height: 250px;
      border: 0px;
    }
  }
}

.signup-heading {
  font-weight: 700;
  font-size: 52px;
  line-height: 52px;
  letter-spacing: -0.06em;
  color: #1e1e1e;
  margin-bottom: 30px;
}

.signup-button {
  all: unset;
  width: 100%;
  padding: 14px 0px;
  background: #1e1e1e;
  border: 1px solid rgba(91, 91, 91, 0.5);
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height, or 136% */
  cursor: pointer;
  text-align: center;
  transition: 0.4s all;
  color: #fcfcfc;
  &:hover {
    background-color: rgba(0, 0, 0, 0.802);
  }
}
.text-help {
  color: #000;
  text-align: center;
  font-size: 16px;
}
.google-button {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
}
@include media("<=medium") {
  .signup-box {
    .signup-img {
      display: none;
    }
    .signup-cta {
      padding-top: 1rem;
    }
  }
}

.signup-already {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  span {
    font-weight: 700;
    color: black;
  }
}

.terms-of-use {
  color: #005fff;
  text-decoration: underline;
}

.error-message {
  font-size: 14px;
  color: red;
  line-height: 0.8rem;
  margin-bottom: 10px;
}

.otp {
  &-heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    color: #000000;
    margin-bottom: 6px;
  }
  &-sub {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    mix-blend-mode: normal;
    opacity: 0.4;
    max-width: 400px;
    margin-bottom: 45px;
  }
}

.email-details {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
  .email {
    font-weight: 700;
  }
}
.otp-not {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  margin-top: 50px;
  color: #000000;
  span {
    color: #444bd3;
    cursor: pointer;
  }
}

.back-btn {
  font-size: 1.5rem;
  margin: 1rem 0;
  color: #000;
  transition: 0.4s all;
}

.back-btn:hover {
  color: #444bd3;
  cursor: pointer;
}
